import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ApiMethod, Endpoints, PagePermssions } from 'src/app/core/services/utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared.module';
import { ModalService } from '../../services/modal/modal.service';
import { AddCommentsComponent } from '../add-comments/add-comments.component';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/core/services/common/common.service';
import { CommentConfig } from 'src/app/core/services/common/common.interface';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-custom-comments',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    NgxPermissionsModule
  ],
  templateUrl: './custom-comments.component.html',
  styleUrl: './custom-comments.component.scss'
})
export class CustomCommentsComponent implements OnInit {
  // permission:any = PagePermssions;
  staticText: any = (textConfiguration as any).default;
  commentsList: any = [];
  @Input() id!: string;
  @Input() apis!: any;
  @Input() permission!: any;
  currentUserInfo: any;

  constructor(private cdr: ChangeDetectorRef,
    private _loader: LoaderService,
    protected _common: CommonService,
    private _http: HttpService,
    public router: Router,
    private _modal: ModalService,
  ) { }


  ngOnInit(): void {
    this.currentUserInfo = JSON.parse(localStorage.getItem('user') || '');
    this.GetCommentsList();
  }

  GetCommentsList() {
    this._loader.show();
    let endpoint: any = this.apis.get + this.id + '/'
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this._loader.hide();
      this.commentsList = response?.data;
    })
  }

  viewFilePreview(filepath: any) {
    this._common.filePreviewModal([filepath?.attachment]).subscribe((result:any) => {
      if (result) {
      }
    })
  }

  addNewComments(ev?: any) {
    const config : CommentConfig = {
      id: this.id,
      enableAttachment: true,
      enableComments : true,
    }
    this._modal
      .openCommonDialog({
        component: AddCommentsComponent,
        data: config
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) { 
          this.addComments(result);
        }
      })
  }

  addComments(data:any){
    let formData = new FormData();
    const payload = {
      "message": data?.message,
      "registration": parseInt(data?.id),
      "company": parseInt(data?.id),
    }

    formData.append('json_data', JSON.stringify(payload));

    if (data.attachements) 
      formData.append('attachments', data.attachements)

    let endpoint: any = `${this.apis.post}`;
    this._loader.show();
    this._http.requestCall(endpoint, ApiMethod.POST, formData, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } }).subscribe((response: any) => {
      this._loader.hide();
      this.GetCommentsList();
    })
  }

}