import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AuthService } from 'src/app/core/services/auth/auth.service'
import { CommonService } from 'src/app/core/services/common/common.service'
import { HttpService } from 'src/app/core/services/http/http.service'
import { LoaderService } from 'src/app/core/services/loader/loader.service'
import { StorageService } from 'src/app/core/services/storage/storage.service'
import { ApiMethod, Endpoints, ErrorCodes } from 'src/app/core/services/utils/constants'
// import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component'
import { DataService } from 'src/app/shared/services/data/data.service'
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sideNav: any;
  // @ViewChild('dashboard',{static:false})dashboard:DashboardComponent;
  @Output() istoggle = new EventEmitter();
  showCollapseState: boolean = true;
  showNotificationPopUp: boolean = false;
  showPopup: boolean = false;
  userDetails: any;
  userName:any;
  staticText: any = (textConfiguration as any).default;
  data:any = {}
  constructor (
    private _storage: StorageService,
    private _loader: LoaderService,
    private _http: HttpService,
    public _common: CommonService,
    private dialog: MatDialog,
    private authService: AuthService,
    private dataService:DataService
  ) {
  }

  ngOnInit (): void {
    this._common.fetchPermssions();
    this.userDetails = this._storage.getUserDetails();
    console.log(this.userDetails, "Usrrdetails");
    this.userName = `${this.userDetails.first_name.charAt(0).toUpperCase()}${this.userDetails.last_name.charAt(0).toUpperCase()}`;


  }
  sideNavToggle(){
    this.sideNav?.toggle();
    // this.dashboard.chartReRender();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.dataService.chartRender.next(true)
  }

  @HostListener('mouseleave')
  onMouseOver () {
    this.showPopup = false;
  }

  


  toggleSidenav () {
    this.dialog.ngOnDestroy()
    this.showCollapseState = !this.showCollapseState
    this.istoggle.emit(this.showCollapseState ? 'standard' : 'icon')
  }

  getProfilePhoto() {
    return this.userDetails?.profile_photo || 'assets/images/common/icons/logo_img/default_profile.svg';
  }
  
  logout () {
    this.clearLoginSession();
  }
  
  clearLoginSession() {
    this._loader.show();    
    let endpoint: any = `${Endpoints.LOGOUT}`;
    this._http.requestCall(endpoint, ApiMethod.POST, {'refresh': this._storage.geRefreshtToken()}).subscribe((response: any) => {

      if(response?.code == ErrorCodes.HTTP_200_SUCCESS){
        this._loader.hide();
        this.authService.logout();    
      }
    })
  }


  @HostListener('document:click', ['$event'])
  onGlobalClick (event: any): void {
    if (
      !document.getElementById('notification')?.contains(event?.target) &&
      !document.getElementById('notificationBox')?.contains(event?.target)
    ) {
      this.showNotificationPopUp = false
    }
  }
}
