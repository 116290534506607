import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { CommonService } from 'src/app/core/services/common/common.service'
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json'
import { Subscription, filter } from 'rxjs'
import { StorageService } from 'src/app/core/services/storage/storage.service'
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service'
import { DataService } from 'src/app/shared/services/data/data.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnChanges, OnDestroy {
  @Input() menuMode: any = 'standard';
  @Input() isViewInSmaller: boolean = false;

  @Input() sideNav: any;
  staticText: any = (textConfiguration as any).default
  selectedMenu: any
  selectedSubMenu: any
  childActive: any
  menuList: any[]
  toggleMenuStatus: boolean[]
  pageLabelClear: boolean = true
  showMenuToggle: boolean = false;
  currentUrl: any
  menuDetails: any
  showCollapseState: boolean = true;
  ;
  
  private routeSubscription: Subscription | undefined = undefined;

  constructor(
    private route: Router,
    private _common: CommonService,
    private dataService:DataService
    // private routeSubscription: Subscription
  ) {
    this.menuList= this._common?.sideNavMenu;
    this.toggleMenuStatus = new Array(this.menuList.length).fill(false)
    this.getCurrentUrl();
  }

  ngOnInit(): void {
    this._common.fetchPermssions();
    this.getCurrentUrl();
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    this.showMenuToggle = this.isViewInSmaller;
     console.log('ngOnChanges', this.isViewInSmaller);
    }   

  sideNavToggle(){
    this.sideNav?.toggle();
    this.dataService.chartRender.next(true);
  }

  toggleSidenav () {
    this.showCollapseState = !this.showCollapseState;
  }

  setId(menu: any) {
    menu.forEach((menu: any, index: any) => {
      menu.id = index + 1
      if (menu?.children?.length) {
        this.setId(menu?.children);
      }
    })
  }

  /**
   * @description
   * CATCH RESPECTIVE MENU
   * @param menu List of array
   * @param uniq_name 
   * @returns NULL OR RESPECTIVE MENU
   */
  catchMenu(menu:any, uniq_name:string):any{
    try {
      return menu?.find(
        (x: any) => x.uniq_name == uniq_name
      )
    } catch (error) {
      return null
    }
  }

  getCurrentUrl() {
    this.routeSubscription = this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.collapseAllMenu(this.menuList);
        this.inactiveAllMenu(this.menuList);
        let activeMenu:any = {};
        event?.url?.split('/').forEach((value:any, index:any) => {
          if (value && (activeMenu?.["level_" + (index-1)]?.children?.length || Object.keys(activeMenu)?.length == 0)) 
            activeMenu["level_" + index] = this.catchMenu(activeMenu?.["level_" + (index-1)]?.children || this.menuList, value);
        });
        this.menuActivate(activeMenu, true);
      })
  }

  protected inactiveAllMenu(menuList?: Array<any>) {
    if (!menuList) return;
  
    menuList.forEach(menu => {
      menu.activate = false;
      if (menu.children?.length) {
        this.inactiveAllMenu(menu.children);
      }
    });
  }

  protected collapseAllMenu(menuList?: Array<any>) {
    if (!menuList) return;
  
    menuList.forEach(menu => {
      menu.expand = false;
      if (menu.children?.length) {
        this.collapseAllMenu(menu.children);
      }
    });
  }

  findMenu(menus: any, currentMenu: any, position: any) {
    let collectData: any = {}
    let findMenu: boolean = false
    menus.forEach((firsrtLevel: any, firsrtLevelIdx: any) => {
      if (findMenu) return
      collectData.firsrtLevel = firsrtLevel
      collectData.secoundLevel = null
      collectData.thirdLevel = null
      if (firsrtLevel.uniqId == currentMenu.uniqId) {
        findMenu = true
      }
      firsrtLevel.children.forEach(
        (secoundLevel: any, secoundLevelIdx: any) => {
          if (findMenu) return
          collectData.secoundLevel = secoundLevel
          collectData.thirdLevel = null
          if (secoundLevel.uniqId == currentMenu.uniqId) {
            findMenu = true
          }
          secoundLevel.children.forEach(
            (thirdLevel: any, thirdLevelIdx: any) => {
              if (findMenu) return
              collectData.thirdLevel = thirdLevel
              if (thirdLevel.uniqId == currentMenu.uniqId) {
                findMenu = true
              }
            }
          )
        }
      )
    })
    return collectData
  }

  /**
   * @description
   * Menu click funtion...........................
   * @param ev Element Reference
   * @param menu current menu
   * @param index  current index
   */
  protected menuClick(ev: any, menu: any, position: any) {
    try {
      let parentData: any
      let expand: any = menu?.expand;
      let active: boolean = menu.children?.length ? false : true
      menu.expand = !expand
    } catch (error: any) {
      throw new Error(error)
    }
  }

  protected menuActivate(parentData: any, active: boolean) {
    let keys: any = Object.keys(parentData)
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index]
      if (parentData[element]) {
        parentData[element].expand = true
        if (active) 
          parentData[element].activate = true;
      }
    }
  }
  
  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    localStorage.removeItem('activeMenu');
  }
}
