import {
  TABLE_COLUMN_OPTIONS,
  TABLE_TOP_FILTERS,
} from 'src/app/shared/components/custom-table/custom-table-model';
import { TranslationService } from 'src/app/shared/services/translation.service';
const staticText = TranslationService.staticTextData;
export const SCP_MANEGEMENT = {
  scp_list_table_fields: {
    company_name: {
      field: staticText?.common?.company ?? 'Company',
      db_key: 'company_name',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__name',
        },
      },
    },
    tin: {
      field: staticText?.common?.tin ?? 'TIN',
      column_options: {
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'company__tin_number',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'tin_number',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_type ?? 'License Type',
      db_key: 'license_type__display_name',
      column_options: {
        column_minWidth: 120,
        column_sorting_key: 'company__company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__license_type__display_name',
        },
      },
    },
    business_site: {
      field: staticText?.common?.business_site ?? 'Business Site',
      column_options: {
        show_popover: true,
        popover_template_name: 'business_site_template',
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'company__company_sites__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company_sites__name',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 120,
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  onboarding_table_fields: {
    registration_code: {
      field: staticText?.common?.registration_code ?? 'Registration Code',
      view_Action: true,
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'code',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'code',
        },
      },
    },
    company_name: {
      field: staticText?.common?.company_name ?? 'Company Name',
      db_key: 'company_name',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__name',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_name ?? 'License Name',
      db_key: 'license_type__display_name',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'company__company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__company__license_type__display_name',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 110,
      },
    },
    status: {
      db_key: 'status__action_complete_label',
      ...TABLE_COLUMN_OPTIONS?.status,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.status?.column_options,
        column_minWidth: 140,
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  table_fields: {
    registration_code: {
      field: staticText?.common?.registration_code ?? 'Registration Code',
      view_Action: true,
      column_options: {
        column_minWidth: 120,
        column_sortable: true,
        column_sorting_key: 'registration_code',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'registration_code',
        },
      },
    },
    company_name: {
      field: staticText?.common?.company_name ?? 'Company Name',
      db_key: 'company_name',
      column_options: {
        column_minWidth: 180,
        column_sortable: true,
        column_sorting_key: 'company__name',
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__name',
        },
      },
    },
    license_type: {
      field: staticText?.common?.license_name ?? 'License Name',
      db_key: 'license_type__display_name',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'company__license_type__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__license_type__display_name',
        },
      },
    },
    ownership_name: {
      field: staticText?.common?.company_ownership_name ?? 'Company Ownership',
      db_key: 'ownership__display_name',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'company__ownership__display_name',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'company__ownership__display_name',
        },
      },
    },
    stage: {
      field: staticText?.common?.stage ?? 'Stage',
      column_options: {
        column_minWidth: 170,
        column_sorting_key: 'stage',
        column_sortable: true,
        column_search_config: {
          search_enabled: true,
          search_type: 'text',
          search_key: 'stage',
        },
      },
    },
    created_on: TABLE_COLUMN_OPTIONS?.created_on,
    created_by: TABLE_COLUMN_OPTIONS?.created_by,
    last_updated_by: TABLE_COLUMN_OPTIONS?.last_updated_by,
    last_updated_on: {
      ...TABLE_COLUMN_OPTIONS?.last_updated_on,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.last_updated_on?.column_options,
        column_minWidth: 110,
      },
    },
    status: {
      db_key: 'status__action_complete_label',
      ...TABLE_COLUMN_OPTIONS?.status,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.status?.column_options,
        column_minWidth: 140,
        column_sortable: false,
        column_sorting_key:
          'status__action_complete_label__or__onboard_company__status__action_complete_label',
        column_search_config: {
          ...TABLE_COLUMN_OPTIONS?.status?.column_options?.column_search_config,
          search_key:
            'status__action_complete_label__or__onboard_company__status__action_complete_label',
        },
      },
    },
    ACTION: {
      ...TABLE_COLUMN_OPTIONS?.action,
      column_options: {
        ...TABLE_COLUMN_OPTIONS?.action?.column_options,
        column_maxWidth: 70,
      },
    },
  },
  scp_list_table_top_filter: [
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.company ?? 'Company',
    },
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.license_type ?? 'License Type',
    },
    {
      ...TABLE_TOP_FILTERS?.date_search,
      placeholder: staticText?.common?.created_on ?? 'Created On',
    },
  ],
  table_top_filter: [
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder: staticText?.common?.license_type ?? 'License Type',
    },
    {
      ...TABLE_TOP_FILTERS?.dd_search,
      placeholder:
        staticText?.common?.company_ownership_name ?? 'Company Ownership',
    },
    {
      status: true,
      name: '',
      placeholder: staticText?.common?.status ?? 'Status',
      type: 'group-by',
      multiple: true,
      groupBy: 'groupData',
    },
    {
      ...TABLE_TOP_FILTERS?.date_search,
      placeholder: staticText?.common?.created_on ?? 'Created On',
    },
  ],
  table_actions: {
    export: true,
  },
  revision_history_table_fields: {
    author: {
      field: 'Author',
      view_Action: true,
      column_options: {
        column_maxWidth: 50,
        column_sortable: false,
      },
    },
    action: {
      field: 'Action',
      column_options: {
        column_maxWidth: 50,
        column_sortable: false,
      },
    },
    section: {
      field: 'Section',
      column_options: {
        column_maxWidth: 50,
        column_sortable: false,
      },
    },
    column: {
      field: 'Column',
      column_options: {
        column_sortable: false,
      },
    },
    after_changes: {
      field: 'Changes',
      column_options: {
        column_sortable: false,
      },
    },
    create_at: {
      field: 'Created at',
      column_options: {
        column_sortable: false,
      },
    },
  },
};

export const formKeys = {
  site_details: staticText?.common?.status_id,
  status_id: staticText?.common?.remarks,
  remarks: staticText?.common?.remarks,
  user: staticText?.common?.user,
  company: staticText?.common?.company_id,
  name: staticText?.common?.site_name,
  facility_type: staticText?.common?.facility_type,
  email: staticText?.common?.email_id,
  contact_no: staticText?.common?.contact_number,
  ownership_percentage: staticText?.common?.percentage_of_ownership,
  billing_address: staticText?.common?.billing_address,
  shipping_address: staticText?.common?.shipping_address,
  address: staticText?.common?.address,
  production_details: staticText?.common?.production_info,
  machine_name: staticText?.common?.machine_name,
  machine_number: staticText?.common?.machine_number,
  machine_type: staticText?.common?.machine_type,
  production_line: staticText?.common?.production_line,
  production_avg: staticText?.common?.production_per_month,
  description: staticText?.common?.description,
  product_details: staticText?.common?.product_and_packaging_info,
  brand_name: staticText?.common?.brand_name,
  product_type: staticText?.common?.product_type,
  category: staticText?.common?.product_category,
  display_name: staticText?.common?.product_name,
  package: staticText?.common?.type_of_packaging,
  uom: staticText?.common?.volume_and_unit,
  maximum_retail_price: staticText?.common?.maximum_retail_price,
  attachments: staticText?.common?.upload_product_image,
  sku_code: `${staticText?.common?.product_code} (${staticText?.common?.gtin})`,
  bank_info: staticText?.scp?.onboard?.sub_heading_2,
  bank_name: staticText?.common?.bank_name,
  branch: staticText?.common?.branch,
  accountant_name: staticText?.common?.account_name,
  account_number: staticText?.common?.account_number,
  account_type: staticText?.common?.account_type,
  bic_code: staticText?.common?.bic_code,
  iban_number: staticText?.common?.iban,
  supplier_info: staticText?.common?.supply_chain_list,
  license_type: staticText?.common?.license_type,
  supply_company: staticText?.common?.supplier_company_name,
  tin_number: staticText?.common?.tin_number,
  user_info: staticText?.scp?.onboard?.sub_heading_4,
  first_name: staticText?.common?.first_name,
  last_name: staticText?.common?.last_name,
  phone_number: staticText?.common?.contact_number,
  site: staticText?.common?.business_site,
  general_info: staticText?.common?.general_info_lbl,
  general_info_attachment: staticText?.common?.attachments,
  general_info_remarks: staticText?.common?.remarks,
  country_code: staticText?.common?.country_code_lbl,
  user_id: staticText?.common?.user_lbl,
  postal_code: staticText?.common?.postal_code,
  location: staticText?.common?.location_lbl,
  host: staticText?.scp?.onboard?.host,
  port: staticText?.scp?.onboard?.port,
  ftp_type: staticText?.scp?.onboard?.ftp_type,
  password: staticText?.scp?.onboard?.password,
  username: staticText?.scp?.onboard?.username,
  api_method: staticText?.scp?.onboard?.api_method,
  target_dir: staticText?.scp?.onboard?.target_dir,
  api_auth_url: staticText?.scp?.onboard?.api_auth_url,
  api_endpoint: staticText?.scp?.onboard?.api_url,
  api_auth_type: staticText?.scp?.onboard?.api_auth_type,
  delivery_type: staticText?.scp?.onboard?.delivery_type,
};
