import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiMethod, Endpoints, PagePermssions, routePath, SCREENMODE, SuccessMessage, USERGROUPS } from 'src/app/core/services/utils/constants';
import { CustomCommentsComponent } from 'src/app/shared/components/custom-comments/custom-comments.component';
import { NextStatusActionComponent } from 'src/app/shared/components/next-status-action/next-status-action.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicFormComponent } from 'src/app/shared/components/dynamic-form/dynamic-form.component';
import { AddCommentsComponent } from 'src/app/shared/components/add-comments/add-comments.component';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { FORM_SECTIONS, ONBOARD_BANKINFO_FORM_SECTIONS, ONBOARD_OTHERINFO_FORM_SECTIONS, ONBOARD_SITEINFO_FORM_SECTIONS, ONBOARD_SUPPLYINFO_FORM_SECTIONS, ONBOARD_USERINFO_FORM_SECTIONS } from '../form-config/form-config.model';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { Title } from '@angular/platform-browser';
import { CommentConfig, CommentsAPIS, CommentsPermission } from 'src/app/core/services/common/common.interface';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { error } from 'jquery';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { OnboardingService } from 'src/app/core/services/registration/onboarding.service';
import { FormSection } from 'src/app/shared/components/dynamic-form/form-sections.model';

@Component({
  selector: 'app-view-registration',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule,
    CustomCommentsComponent,
    NextStatusActionComponent,
    DynamicFormComponent
  ],
  templateUrl: './view-registration.component.html',
  styleUrl: './view-registration.component.scss'
})
export class ViewRegistrationComponent implements OnInit {
  screenMode: any = SCREENMODE;
  permission: any = PagePermssions;
  editId: any;
  nextStatus: any;
  disableForm: boolean = false;
  mode: string = 'view';
  apiEndpoint: any;
  formConfig: any;
  loadMasterAPI: string = 'certificate_info';
  selectedIndex: number = 0;
  registrationData: any = null;
  locationdetails: any = [];
  staticText: any = (textConfiguration as any).default;
  pageMode: any;
  operationType: any;
  selectedFiles: { [key: string]: File } = {};

  @ViewChild(DynamicFormComponent) dynamicFormComponent!: DynamicFormComponent;

  bankInfoConfig: FormSection[] = [];
  supplyChainInfoConfig: FormSection[] = [];
  userInfoConfig: FormSection[] = [];
  otherInfoConfig: FormSection[] = [];
  licenseType: any = null;

  onboardData: any;
  siteInfoConfig: any = [];
  otherInfoData: any;
  bankInfoMode: any;
  supplyChainMode: any;
  userMode: any;
  otherMode: any;
  scpHeaderInfo: any = {};
  viewMode: any = null;
  onboard_commentsApis: CommentsAPIS = {
    "get": Endpoints.ONBOARD_COMMENTS,
    "post": Endpoints.ONBOARD_COMMENTS_CREATE
  }
  commentsApis: CommentsAPIS = {
    "get": Endpoints.COMMENTS,
    "post": Endpoints.COMMENTS_CREATE
  }

  onboard_commentsPermission: CommentsPermission = {
    "view": PagePermssions.CAN_VIEW_ONBOARDCOMMENT,
    "add": PagePermssions.CAN_ADD_ONBOARDCOMMENT
  }
  commentsPermission: CommentsPermission = {
    "view": PagePermssions.CAN_VIEW_REGISTRATIONCOMMENT,
    "add": PagePermssions.CAN_ADD_REGISTRATIONCOMMENT
  }

  constructor(
    private _onBoard: OnboardingService,
    private _modal: ModalService,
    private _http: HttpService,
    private activated_route: ActivatedRoute,
    private _common: CommonService,
    private _storage: StorageService,
    private _loader: LoaderService,
    private _router: Router,
    private _lodash: LodashService,
    private datePipe: DatePipe,
    private _formValidator: FormValidatorService,
    protected breadcrumbService: BreadCrumbService,

  ) { }

  ngOnInit(): void {
   /* FORM_SECTIONS?.forEach(element => {
      if (element.key == 'ownership_info') {
        element.meta.withBorder = true
      }
    }); */

    
    this.breadcrumbService.breadCrumbView(false);
    this.formConfig = FORM_SECTIONS;
    this.editId = this.activated_route.snapshot.paramMap.get('id');
    this.viewMode = this.activated_route.snapshot.paramMap.get('view-mode');
    this.checkOnboardDataExist(this.editId);

    this.bankInfoConfig = ONBOARD_BANKINFO_FORM_SECTIONS;
    this.supplyChainInfoConfig = ONBOARD_SUPPLYINFO_FORM_SECTIONS;
    this.userInfoConfig = ONBOARD_USERINFO_FORM_SECTIONS;
    this.otherInfoConfig = ONBOARD_OTHERINFO_FORM_SECTIONS;

    this.apiEndpoint = Endpoints.GET_SCP_REGN_DETAIL + this.editId; 
    this.getRegistrationData(this.apiEndpoint);
    this.getRouteParams();
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    this.operationType = routeInfo?.value?.operation_type;
  }

  edit() {
    this._router.navigate(['scp-mgmt/edit-registration/' + this.editId]);
  }
  

  navigateToHistory() {
    if (this.operationType == this.screenMode?.REGISTRATION) {
      this._router.navigate(['reports/audit-log/scp_registration/' + this.editId])
    } else {
      this._router.navigate(['reports/audit-log/scp_onboard/' + this.editId])
    }
  }

  view() {
    if (this.operationType == this.screenMode?.REGISTRATION) {
      this._router.navigate(['scp-mgmt/view-status/' + this.editId]);
    } else if (this.operationType == this.screenMode?.ONBOARDING) {
      this._router.navigate(['scp-mgmt/view-onboarding-status/' + this.editId]);
    }
  }

  editOnboarding(index: any) {
    if (index)
      this._router.navigate(['scp-mgmt/onboard-scp/' + this.editId + '/' + index]);
    else
      this._router.navigate(['scp-mgmt/onboard-scp/' + this.editId]);
  }

  submit() {

  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {

  }

  expandSiteDetails(item: any, items: any) {
    item.expand = !item.expand;
  }

  /**
   * @description
   * Filter array keyname id's............................
   * @param data 
   * @returns 
   */
  transformData(data: any, keyName: string) {
    return data?.map((item: any) => {
      let transformed: any = { id: item.id, key: keyName, display_name: item.display_name };
      if (item.child_keys.length > 0) {
        transformed.child = [];
        item.child_keys.forEach((key: string) => {
          transformed.child = transformed.child.concat(this.transformData(item[key], key));
        });
      }

      return transformed;
    });
  }

  //............................Filter array keyname id's

  /**
   * @description 
   * Check the all onboarding section complete or not
   * @returns respective boolean
   */

  get hasOnboardfullfill(): boolean {

    return !!this._onBoard.onboardingInfo.site_info?.length &&
      this._onBoard.onboardingInfo?.supplier_info?.length &&
      this._onBoard.onboardingInfo.user_info?.length &&
      this._onBoard.onboardingInfo?.general_info?.id &&
      this._onBoard.onboardingInfo?.bank_info?.id &&
      this.scpHeaderInfo?.onboard_exists
  }

  /**
   * @description 
   * Before complete enable edit
   * @returns respective boolean
   */

  get hasEditOnboarding(): boolean {
    return !this.scpHeaderInfo?.onboard_complete
  }


  get viewLocationDetails(): any {
    let groupDetails = [];
    for (let index = 0; index < Object.keys(this.locationdetails).length; index++) {
      const element = Object.keys(this.locationdetails)[index];
      let obj: any = {};
      obj.display_name = element;
      obj.group = this.locationdetails[element];
      obj.concatName = this.locationdetails[element]?.map((x: any) => x.display_name)?.toString();
      groupDetails.push(obj)
    }
    return groupDetails
  }

  /**
   * @description
   * Nested JSON built as flattern JSON................................
   * @param jsonData 
   * @param deleteMode 
   * @returns 
   */

  flattenJson(jsonData: any, deleteMode: boolean) {
    let result: any = [];

    function flatten(item: any) {
      result.push(item);
      if (item.child) {
        item.child.forEach(flatten);
        if (deleteMode) {
          delete item.child;
        }
      }
    }
    jsonData?.forEach(flatten);
    return result;
  }


  getOnboardSiteFormConfig(licenseType: any) {
    if (licenseType?.name !== 'Manufacturer') {
      const clonerFormConfig = ONBOARD_SITEINFO_FORM_SECTIONS.map(x => x);;
      const formConfig = this._common.removeSectionByKey(clonerFormConfig, 'production_details');
      return formConfig.map(x => x);
    } else {
      return ONBOARD_SITEINFO_FORM_SECTIONS.map(x => x);
    }
  }

  getOnboard(id: any) {
    this._onBoard.getOnboard(id).subscribe((response: any) => {
      this._onBoard.onboardingInfo = response?.data;
      this.onboardData = response?.data ?? {};
      this._onBoard.onboardingInfo.site_info.forEach((element: any, index: any) => {
        this.siteInfoConfig.push({
          name: element?.site_details?.name,
          mode: "view",
          valid: true,
          expand: index == 0 ? true : false,
          data: element,
          section: this.getOnboardSiteFormConfig(this.licenseType)
        });
      });

      this.otherInfoData = this.onboardData?.general_info;
      this.bankInfoMode = this.onboardData?.bank_info?.id ? 'edit' : 'add';
      this.supplyChainMode = this.onboardData?.supplier_info?.length ? 'edit' : 'add';
      this.userMode = this.onboardData.user_info?.length ? 'edit' : 'add';
      this.otherMode = this.onboardData?.general_info?.id ? 'edit' : 'add';
    })
  }

  checkOnboardDataExist(id: any) {
    this._onBoard.getSCPRegistrationHeaderInfo(id).subscribe((response: any) => {
      const data = response?.data || {};
      this.licenseType = data.license_type ?? {};
      this.scpHeaderInfo = data;
      if (this.scpHeaderInfo?.onboard_exists &&
        this.operationType == this.screenMode?.ONBOARDING
      ) {
        if (this._common.gotPermission([this.permission.VIEW_ONBOARD], true) &&
          this.scpHeaderInfo?.onboard_complete) {
          this.getOnboardingNextStatus(this.editId);
        }
        this.getOnboard(this.editId);
      } else {
        this.getNextStatus(this.editId);
      }
    });
  }

  getRegistrationData(endpoint: any) {
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.registrationData = response?.data;
      if (this.pageMode == this.screenMode?.VIEW) {
        let locationDetails: any = [];
        this.registrationData.business_info.location?.child_keys?.forEach((key: any) => {
          locationDetails = this.transformData(this.registrationData.business_info.location[key], key);
        });
        const locationdetails = this.flattenJson(locationDetails, true);
        this.locationdetails = this._lodash.groupBy(locationdetails, 'key');
      }
    });
  }

  getNextStatus(id: any) {
    let endpoint: any = Endpoints.GET_NEXT_STATUS + id;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.nextStatus = response?.data;
    });
  }

  getOnboardingNextStatus(id: any) {
    let endpoint: any = Endpoints.GET_ONBOARDING_NEXT_STATUS + id;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.nextStatus = response?.data;
    });
  }

  nextActionEmit(action: any) {
    this.getComments(action)
  }

  getComments(action: any) {
    const nextStatus = this._lodash.find(this.nextStatus.next_states, "slug", action);
    const config: CommentConfig = {
      id: this.editId,
      enableComments : true,
      enableAttachment: false,
      header: {
        title: nextStatus.label
      },
      footer: {
        cancelBtnTxt: "Cancel",
        submitBtnTxt: nextStatus.label,
        submitBtnStyle: { 'background-color': nextStatus.colorcode || '#0061f7' }
      }
    }
    this._modal
      .openCommonDialog({
        component: AddCommentsComponent,
        data: config
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          if (this.scpHeaderInfo?.onboard_complete)
            this.updateOnboardStatus(result, action);
          else
            this.updateStatus(result, action);
        }
      })
  }

  close() {
    const userDetails: any = this._storage.getUserDetails();
    if (userDetails?.groups?.[0]?.name == USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR)
      this._router.navigate([`${routePath?.SCP_APPLICATION_PATH}`]);
    else {
      if (this.viewMode)
        this._router.navigate([routePath.SCP_LIST]);
      else if (this.scpHeaderInfo?.onboard_exists)
        this._router.navigate([routePath.ONBOARD_SCP_PATH]);
      else
        this._router.navigate([routePath.SCP_PATH]);
    }
  }

  updateStatus(data: any, action: string) {
    let formData = new FormData();
    const payload = {
      "comment": data?.message
    }
    formData.append('json_data', JSON.stringify(payload));
    if (data.attachements)
      formData.append('attachments', data.attachements);
    let endpoint: any = `${Endpoints.UPDATE_SCP_STATUS}${data?.id}/?status_key=${action}`;
    this._loader.show();
    this._http.requestCall(endpoint, ApiMethod.PUT, formData, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } }).subscribe((response: any) => {
      this._loader.hide();
      this._modal.openSucceedDialog({ data: { 
        header: 'Success!',
        msg: response?.body?.data?.message,
        paragraph: response?.body?.data?.message } })
        .afterClosed()
        .subscribe((result: any) => {
          if (result) {
            this._router.navigate([routePath.SCP_PATH]);
          }
        });
    })
  }

  updateOnboardStatus(data: any, action: string) {
    let formData = new FormData();
    const payload = {
      "comment": data?.message
    }
    formData.append('json_data', JSON.stringify(payload));
    if (data.attachements)
      formData.append('attachments', data.attachements);
    let endpoint: any = `${Endpoints.UPDATE_ONBOARD_STATUS}${data?.id}/?status_key=${action}`;
    this._loader.show();
    this._http.requestCall(endpoint, ApiMethod.PUT, formData, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } }).subscribe((response: any) => {
      this._loader.hide();
      this._modal.openSucceedDialog({ data: { 
        header: 'Success!',
        msg: response?.body?.data?.message,
        paragraph: response?.body?.data?.message } })
        .afterClosed()
        .subscribe((result: any) => {
          if (result) {
            this._router.navigate([routePath.ONBOARD_SCP_PATH]);
          }
        });
    })
  }

  triggerValidation() {
    if (this.dynamicFormComponent) {
      this.dynamicFormComponent.validateForm();

      if (this.dynamicFormComponent?.isFirstGroupEmpty('ownership_info')) {
        this._formValidator.validOwnershipData();
      }
    } else {
      console.error('DynamicFormComponent is not available');
    }
  }

  handleFormSubmit(formData: any) {
    if (this.dynamicFormComponent?.form?.valid && this._formValidator.validateOwnershipPercentage(formData)) {
      // formData.business_info.location= 1;
      const formDataObject = new FormData();
      this.selectedFiles = {};

      // Transform the certificate_info
      const transformedCertificateInfo = formData.certificate_info.map((info: any) => {
        // Ensure info.attachments is an array
        const attachments = Array.isArray(info.attachments) ? info.attachments : [];

        // Map attachments to unique names and store in selectedFiles
        const uniqueAttachments = (attachments.length) ? attachments.map((file: any) => {
          if (!file || !file?.name || file.id) {
            return "";
          }

          const uniqueName = this._common.generateUniqueFileName(file);
          this.selectedFiles[uniqueName] = file; // Store file with unique name
          return uniqueName || "";
        }) : [];
        // Remove empty strings or null values
        const cleanedAttachments = uniqueAttachments.filter((attachment: any) => attachment && attachment.trim() !== "");

        return {
          id: info.id,
          certificate: info.certificate,
          certificate_number: info.certificate_number,
          expiry_date: info.expiry_date,
          attachments: cleanedAttachments,
          is_not_applicable: info.is_not_applicable
        };
      });

      formData['business_info']['old_email'] = this.registrationData?.business_info?.email;
      // Include the entire form data with the transformed certificate info
      const completeFormData = {
        ...formData,
        certificate_info: transformedCertificateInfo,
        id: this.editId
      };

      // Append the transformed certificate info as JSON
      formDataObject.append('json_data', JSON.stringify(completeFormData));

      // Append files directly
      if (this._common.isNotEmptyObject(this.selectedFiles)) {
        Object.keys(this.selectedFiles).forEach(uniqueName => {
          const file = this.selectedFiles[uniqueName];
          formDataObject.append(uniqueName, file, file.name);
        });
      }

      // Proceed with the form submission
      this.submitFormData(formDataObject);
    } else {
      // Handle form validation errors
      console.error('Form is invalid');
    }
  }

  // Separate method to submit the form data to the API
  submitFormData(formDataObject: FormData) {
    console.log('Form data to be submitted:', formDataObject);
    this._loader.show();
    let endpoint: any = Endpoints.CREATE_REGISTERATION_API + this.editId + '/';
    this._http.requestCall(endpoint, ApiMethod.PUT, formDataObject, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } }).subscribe(
      (response: any) => {
        console.log(response);
        this._loader.hide();
        if (response.ok || response.status == 201) {
          let data = response?.body?.data || {};
          let businessInfo = data?.business_info || {};
          const regnID = data?.validation_info?.registration_code || "";
          let msg = this.staticText?.scp?.register?.view_edit?.regn_update_success_msg || response.message;
          let bdyContent = `<div class="regn-success-panel">
          <div class="row m-2">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Licence Type</span></label>
            <span class="color-darkgrey fw-600">`+ businessInfo?.license_type?.display_name + `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Last Updated Date</span></label>
            <span class="color-darkgrey fw-600">`+ this.datePipe.transform(new Date(), 'dd MMM yyyy') + `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Request ID</span></label>
            <span class="color-darkgrey fw-600">`+ regnID + `</span>
          </div>
          </div> 
          </div>`;
          this._modal.openSucceedDialog({
            data: { header: "Changes has been Saved!", msg: `<p class="fs-14">` + msg + `</p>`, template : bdyContent }
          }).afterClosed().subscribe(result => {
            if (result) {
              // Handle successful form submission
              this.view();
            }
          });
        } else {
          this._modal.openWarningDialog({
            data: { paragraph: `<p class="fs-14">` + "Error during form submission. Please check the details and try again." + `</p>` }
          }).afterClosed().subscribe(result => {
            if (result) {
              // Handle form submission error
            }
          });
        }
      },
      (error: any) => {
        this._loader.hide();
        console.error('Error during form submission:', error);
      }
    );
  }

  /**
   * 
   * @param id 
   * @param formData 
   */
  saveOnboardDetails(id: any) {
    this._loader.show();

    const payload = { 'is_complete': true };
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));

    this._onBoard.updateDetails(id, formData).subscribe((response: any) => {
      this._loader.hide();
      let msg = this._lodash.getData(response, 'body.message', false);
      if (msg) {
        let bdyContent = `<div class="regn-success-panel">
          <div class="row m-2">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Licence Type</span></label>
            <span class="color-darkgrey fw-600">`+ response?.body?.data?.license_type + `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Last Updated Date</span></label>
            <span class="color-darkgrey fw-600">`+ this.datePipe.transform(new Date(), 'dd MMM yyyy') + `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Request ID</span></label>
            <span class="color-darkgrey fw-600">`+ response?.body?.data?.code + `</span>
          </div>
          </div> 
          </div>`;

        this._modal.openSucceedDialog({
          data: { header: "Onboarding Process Completed!", msg: `<p class="fs-14">` + msg + `</p>`, template : bdyContent }
        }).afterClosed().subscribe(result => {
          if (result) {
            // Handle successful form submission
            this.close();
          }
        });

      }
    })
  }


}
