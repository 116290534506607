import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from './core/services/common/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'uspTaxStamp';
  currentUrl: any;
  userMode: any;
  paramSubscription!: Subscription;
  routeSubscription!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _common: CommonService) { }


  ngOnInit(): void {
    // Access the mode (prefix) from the URL
    this.paramSubscription = this.route.params.subscribe(params => {
      this.userMode = params['usermode'];
    });

    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) 
        this.currentUrl = event.url;
      //if (event instanceof NavigationStart) 
        //this.handleRouteChange(event.url);
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  handleRouteChange(url: string): void {
    const prefix = url.split('/')?.[1];
    this._common.getSetRouteValue(url);
    const newUrl = url.replace(prefix, this._common.routePrefix);
    if (prefix !== this._common.routePrefix) {
      this.router.navigate([newUrl]);
    }

  }

}
