<section class="header container-fluid">
    <div class="row d-flex align-items-center py" #header>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="header_flx">
                <div class="header_logo">
                    <!-- <div class="p-1 cursor-pointer" (click)="sideNavToggle();showCollapseState =!showCollapseState">
                        <img [src]="showCollapseState ?'assets/images/layout/header/sidenav_open.svg':'assets/images/layout/header/sidenav_close.svg'"
                            [alt]="showCollapseState ? 'toggleSideNav_open':'toggleSideNav_close'">
                    </div> -->
                    <div class="application-logo">
                        <img src="assets/images/common/logo.svg" class="" alt="">
                    </div>

                    <div class="">
                        <div class="mb-0  fw-300 d_flx_rwanda">
                            <span class="username">
                                <img src="assets/images/common/rwanda_logo.svg" class="" alt="">
                            </span>
                            <span class="rwanda_img">{{staticText?.common?.company_secondary_name || "Rwanda Digital Tax Stamp System"}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex cursor-pointer"  >
                    <div class="d-flex align-items-center logout_btn" (click)="showPopup=true">
                        <p class="mb-0 profile_avatar avatar-image mx-1">
                            <img [src]="getProfilePhoto()" alt="logo_profile" > 
                        </p>
                        <div class="profile-arrow mx-1">
                            <img src="/assets/images/common/icons/dropdown_arrow.svg" alt="arrow" >
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showPopup">
                    <div class="profile-popup-container" (mouseleave)="showPopup=false" >
                        <div class="profile-items d-flex flex-column py-2">
                            
                            <div class="profile-item fs-14 cursor-pointer p-2" (click)="logout()">
                                <img   class="img" src="/assets/images/common/icons/logout.svg" alt="" >
                                <a>Logout</a>
                            </div>
                        </div>
                    </div>
                </ng-container> -->

                <!-- User Profile -->
                 <div class="jd_profile">

               <div class="pointerEventNone">
                <img src="/assets/images/common/icons_usp/notofication.svg" alt="">
               </div>

                <div class="p-1 cursor-pointer login_flx login_flx_logo " [matMenuTriggerFor]="profilePopUpBox">
                    <!-- <span class="notification">
                        <img src="assets/images/layout/header/notofication.svg" class="img-fluid">
                    </span>  -->
                  <div class="profile_icn">{{userName}}</div>
                    
                </div>
                </div>




                <mat-menu #profilePopUpBox="matMenu" class="profile-pop-up-box border-8px">
                    <div class="d-flex flex-column container-fluid p-3 py-2">
                        <div class="p-1 border-bottom">
                            <div class="d-flex flex-row ">
                                <!-- <div class="p-1">
                                        <img [src]="getProfilePhoto()" class="rounded-circle" alt="profile" width="48" height="48" > 
                                </div> -->
                                <div class="d-flex flex-column p-1 ps-0">
                                    <label class=" f-18 custom-word-ellipse text-capitalize">

                                        <span class="user_fs">{{userDetails?.username || userDetails?.full_name}}</span>
                                    </label>
                                    <!-- <ng-container *ngIf="role || data?.roles?.[0]?.name">
                                        <div class="roleLbl">
                                            <span class="text-capitalize"> {{role || data?.roles?.[0]?.name}}</span>
                                        </div>
                                    </ng-container> -->
                                    <label class="color-primary f-14 custom-word-ellipse cursor-pointer"
                                        matTooltip="User Details">{{data?.email}}</label>
                                </div>
                            </div>
                            <ng-container>
                                <div class="role_name">
                                    <span class="text-capitalize"> {{userDetails?.groups[0]?.display_name || ''}}</span>
                                </div>
                                <div class="entity_name">
                                    <span class="text-capitalize"> {{userDetails?.entity?.display_name || ''}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <div class="p-1 mt-2 text-capitalize" (click)="logout()">
                            <h5 class="fs-16 mb-0 cursor-pointer" [innerHtml]="staticText?.header?.header_button_1">
                            </h5>
                        </div>
                    </div>
                </mat-menu>

            </div>
        </div>

    </div>
</section>

<!-- <ng-container *ngIf="showNotificationPopUp">
    <div class="log-container" id="notificationBox">
        <div class="arrow-top"></div>
        <div class="pt-4 p-2">
            <div class="d-flex flex-column cursor-pointer">
                <div class="p-1 border-bottom">
                    <h5 class="color-Port-Gore fs-14">Notification List</h5>
                </div>
                <div class="popup_body">
                    <ul class="list-group">
                        <li class="list-group-item">Cras justo odio</li>
                        <li class="list-group-item">Dapibus ac facilisis in</li>
                        <li class="list-group-item">Morbi leo risus</li>
                        <li class="list-group-item">Porta ac consectetur ac</li>
                        <li class="list-group-item">Vestibulum at eros</li>
                        <li class="list-group-item">Morbi leo risus</li>
                        <li class="list-group-item">Porta ac consectetur ac</li>
                        <li class="list-group-item">Vestibulum at eros</li>
                        <li class="list-group-item">Morbi leo risus</li>
                        <li class="list-group-item">Porta ac consectetur ac</li>
                        <li class="list-group-item">Vestibulum at eros</li>
                        <li class="list-group-item">Morbi leo risus</li>
                        <li class="list-group-item">Porta ac consectetur ac</li>
                        <li class="list-group-item">Vestibulum at eros</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-container> -->