<mat-drawer-container class="content-layout-container"
    [ngClass]="isViewInSmaller ? 'smallerscreen-menu': 'largerscreen-menu'" hasBackdrop="false" autosize>
    <mat-drawer #sideBarNavigation mode="push" class="sidenav" opened="true">
        <!-- sideNav component -->
        <!-- <app-sidebar [sideNav]="sideBarNavigation" [isViewInSmaller]="isViewInSmaller"  (istoggle)="sideBarMode=$event" [menuMode]="sideBarMode || 'standard'"></app-sidebar> -->
    </mat-drawer>

    <mat-drawer-content class="main-content">
        <!-- header component -->
        <app-header class="no-print" [sideNav]="sideBarNavigation" (istoggle)="sideBarMode=$event"></app-header>
        <app-top-navbar class="no-print" [sideNav]="sideBarNavigation" (istoggle)="sideBarMode=$event"></app-top-navbar>

        <div class="entry-point container-custom">
            <div class="page_bg">

                <div class="d-flex flex-column entry-container">
                    <!-- breadcrumb -->
                    <!-- page entry outlet-->
                    <div class="pt-3 px-4 bg_page" *ngIf="_breadCrumb && _breadCrumb?.enableBreadCrumb">
                        <div class="">
                            <div class="d-flex" *ngIf="false">
                                <span class="align-items-center d-flex fs-24 me-2 pe-2 me-2 cursor-pointer" *ngIf="_breadCrumb && _breadCrumb.navigation">
                                    <!-- <img src="/assets/images/common/icons/{{_breadCrumb.navigation.icon}}.svg"
                                        alt="back" class="navigate_right" (click)="goBack()"> -->

                                        <span (click)="goBack()" > 
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </span>
                                </span>
                                <div>
                                    <h3 class="text-capitalize fs-24 fw-bold m-0 role_title"
                                    [innerHtml]="_breadCrumb.PageTitleLabel"> </h3>
                                    <bread-crumb></bread-crumb>
                                </div>
                            </div>
                            <!-- <div class="mb-3"> 
                                <bread-crumb></bread-crumb>
                            </div> -->

                            <div class="bread-crumb-title">
                                <div class="d-flex align-items-center fs-24 cursor-pointer" >
                                    <span (click)="goBack()" *ngIf="_breadCrumb && _breadCrumb.navigation"> 
                                        <i class="fa-solid fa-arrow-left"></i>
                                    </span>
                                </div>
                                <div>
                                    <h3 class="text-capitalize fs-24 fw-bold m-0 role_title"
                                    [innerHtml]="_breadCrumb.PageTitleLabel"> </h3>
                                </div>
                                <div class="bread-crumb">
                                    <bread-crumb></bread-crumb>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="d-flex align-items-center justify-content-between" *ngIf="_breadCrumb?.doc_counts">

                            <div class="doc_count_card" *ngFor="let docs of _breadCrumb?.doc_counts">
                                <div>
                                    <span class="fs-10" style="color: whitesmoke;" [innerHtml]="docs?.name"></span>
                                </div>
                                <div>
                                    <span class="fs-10" [innerHtml]="valuationDocument ? valuationDocument : '--' "></span>
                                </div>
                            </div>
                        </div>
                        </div> -->

                        <ng-container *ngIf="showAction">

                            <div class="d_flx_add"
                                *ngxPermissionsOnly="_breadCrumb?.action_items?.permission">
                                <ng-container class="" *ngIf="_breadCrumb && _breadCrumb.uploadAction">
                                    <button mat-raised-button class="upload-default-style"
                                        (click)="uploadDetails($event)">
                                        <div>
                                            <span [innerHtml]="_breadCrumb.uploadAction.label" class="fw-bold"></span>
                                        </div>
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="_breadCrumb?.text_action">
                                    <ng-container *ngxPermissionsOnly="[_breadCrumb.text_action.editPermission]">
                                        <div class="text-white cursor-pointer"
                                            (click)="_breadCrumb.text_action.onClickEditActionFn ? _breadCrumb.text_action.onClickEditActionFn() : false">
                                            <!-- <img class="px-2" *ngIf="_breadCrumb?.text_action.icon" [src]="'assets/images/common/icons/edit.svg'"
                                                    alt="button_icon"> -->
                                            <span class="view-to-edit fa fa-edit"></span>
                                            <span [innerHtml]="'Edit'" class="fw-bold"></span>
                                        </div>
                                        <span class="mx-2"></span>
                                    </ng-container>
                                    <div class="text-white cursor-pointer"
                                        (click)="_breadCrumb.text_action.onClickActionFn ? _breadCrumb.text_action.onClickActionFn() : false">
                                        <img class="px-2" *ngIf="_breadCrumb.text_action.icon"
                                            [src]="'assets/images/common/icons/'+ _breadCrumb.text_action.icon + '.svg'"
                                            alt="button_icon">
                                        <span [innerHtml]="_breadCrumb.text_action.label" class="fw-bold"></span>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div class="edit_flx" *ngIf="_breadCrumb?.action_items">
                                        <button *ngIf="_breadCrumb.action_items.router" mat-raised-button
                                            class=" new_user_btn" routerLink="{{_breadCrumb.action_items.router}}">
                                            <div>
                                                <span [innerHtml]="_breadCrumb.action_items.label"
                                                    class="text-light"></span>
                                                <img class="px-2"
                                                    [src]="_breadCrumb.action_items.icon ? 'assets/images/common/icons/'+ _breadCrumb.action_items.icon + '.svg' : 'assets/images/common/icons/arrow_right_dark.svg'"
                                                    alt="button_icon">
                                            </div>
                                        </button>
                                        <!-- <div class="edit_icn">
                                            <img src="assets/images/common/icons/more.svg" class="img-fluid">
                                        </div> -->
                                        <button
                                            *ngIf="_breadCrumb.action_items.onClickActionFn && !_breadCrumb.action_items.customFields?.length"
                                            mat-raised-button class=" new_user_btn"
                                            (click)="_breadCrumb.action_items.onClickActionFn()">
                                            <div>
                                                <!-- <img class="px-2"
                                                    [src]="_breadCrumb.action_items.icon ? 'assets/images/common/icons/'+ _breadCrumb.action_items.icon + '.svg' : 'assets/images/common/icons/arrow_right_dark.svg'"
                                                    alt="button_icon"> -->
                                                <span [innerHtml]="_breadCrumb.action_items.label"
                                                    class="text-light"></span>

                                            </div>
                                        </button>
                                        <div *ngIf="_breadCrumb.action_items.customFields?.length"
                                            class="action-layout">
                                            <div class="action-labels-section">
                                                <ng-container *ngFor="let f of _breadCrumb.action_items.customFields">
                                                    <div class="action-labels">
                                                        <div class="action-label-title">
                                                            {{f?.fieldName || '&nbsp;'}}
                                                        </div>
                                                        <div class="action-label-val" [title]="f?.fieldVal">
                                                            {{f?.fieldVal || '&nbsp;'}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <ng-container
                                                *ngxPermissionsOnly="_breadCrumb.action_items.actionBtn?.permission || []">
                                                <button mat-raised-button *ngIf="_breadCrumb.action_items.actionBtn"
                                                    [ngClass]="{'cursor-not-allowed': _breadCrumb.action_items.actionBtn?.isReadOnly}"
                                                    [disabled]="_breadCrumb.action_items.actionBtn?.isReadOnly"
                                                    class="new_user_btn"
                                                    (click)="_breadCrumb.action_items.actionBtn?.onClickActionFn()">
                                                    <div>
                                                        <span [innerHtml]="_breadCrumb.action_items.actionBtn?.label"
                                                            class="text-light"></span>
                                                        <img class="px-2"
                                                            [src]="_breadCrumb.action_items.actionBtn?.icon ? 'assets/images/common/icons/'+ _breadCrumb.action_items.actionBtn?.icon + '.svg' : 'assets/images/common/icons/arrow_right_dark.svg'"
                                                            alt="button_icon">
                                                    </div>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </ng-container>


                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
            
        </div>
        <app-footer></app-footer>
    </mat-drawer-content>
</mat-drawer-container>