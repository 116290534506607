<div class="filter_flx flx_roles_col  pt_users d-flex align-items-center w-100">
    <div class="flex-column filter_title me-auto">

        <div class="text-capitalize mb-0  list_heading">
            @if (pageInfo.value.mode == scpEnum.scp_list) {
            <span>{{staticText?.scp?.view_register?.scp_list}}</span>
            }
            @if (pageInfo.value.mode == scpEnum.registration) {
            <span>{{staticText?.scp?.view_register?.scp_card_title}}</span>
            }
            @if (pageInfo.value.mode == scpEnum.onboard) {
            <span>{{staticText?.scp?.view_register?.scp_onboard_card_title}}</span>
            }


            <span class="total_records mx-2" *ngIf="scpList">({{totalRecords}})</span>
        </div>
        @if (pageInfo.value.mode == scpEnum.scp_list) {
        <span class="users_clr">{{staticText?.scp?.view_register?.scp_list}}</span>
        }
        @if (pageInfo.value.mode == scpEnum.registration) {
        <span class="users_clr">{{staticText?.scp?.view_register?.scp_card_title}}</span>
        }
        @if (pageInfo.value.mode == scpEnum.onboard) {
        <span class="users_clr">{{staticText?.scp?.view_register?.scp_onboard_card_title}}</span>
        }


    </div>
    <div class="custom_filter_flx w_full_users d-flex justify-content-end align-items-center">

        <div class="d-flex w_full_filter align-items-center">


            <custom-table-filter class="w_full_filter" [customTableFilterLabelClass]="''"
                [customTableFilterContainerClass]="'role_width_new'" [customTableFilterSource]="filterSource"
                (customTableFilterValueEmitter)="filterParams($event)">
            </custom-table-filter>
            <custom-table-actions [addBtnTitle]="staticText?.scp?.view_register?.scp_onboard_card_title"
                [addButton]="false" class="pos_export" [isButtonAction]="true" [tableExport]="tableExport"
                [downloadAPI]="listAPI" [filterParams]="filterParamsExport"
                [tableFields]="tableFields"></custom-table-actions>
        </div>




    </div>
</div>
<div class="sts_total">
    <div class="registration_gap">
        <div class="inline_flx">

            <div class="registration_details">
                <span class="registration_txt_sts">{{staticText?.scp?.view_register?.registration_txt}} -
                    {{registrationTotalCnt ?? 0}}</span>


            </div>

            <div class="registration_details_flx cursor-pointer" (click)="triggerFilter(item)" [ngStyle]="{
    backgroundColor: _common.convertToRgba(item?.color_code, 0.1),
    'border-left': '3px solid ' + item?.color_code 
  }" *ngFor="let item of registerStatus">
                <span class="registration_txt cursor-pointer">{{item?.action_complete_label}}</span>
                <span class="registration_count cursor-pointer">{{item?.total_count}}</span>

            </div>




        </div>
    </div>

    <div class="registration_gap">
        <div class="inline_flx border-top-0">


            <div class="registration_details">
                <span class="registration_txt_sts">{{staticText?.scp?.view_register?.onboard_txt}} -
                    {{onboardTotalCnt ?? 0}}</span>
            </div>



            <!-- {{statusCount | json}} -->
            <div class="registration_details_flx cursor-pointer " (click)="triggerFilter(item)" [ngStyle]="{
    backgroundColor: _common.convertToRgba(item?.color_code, 0.1),
    'border-left': '3px solid ' + item?.color_code 
  }" *ngFor="let item of onboardStatus">
                <span class="registration_txt cursor-pointer">{{item?.action_complete_label}}</span>
                <span class="registration_count cursor-pointer">{{item?.total_count}}</span>

            </div>


        </div>

    </div>
</div>



<mat-card-content>

    <div class="d-flex flex-column p-4 pt-2">
        <custom-table tableId="cust-tbl-rolelist" [localColumnSearch]="true" [columnSearchOptionsList]="[]"
            [internalColumnSearch]="false" (columnSearch)="columnSearch($event)"
            [tableCustomActionSelectAll]="tableHeader" [columnDefs]="tableFields" [tablePaginationCustom]="true"
           [stickyColumnSize]="2" [stickyClass] = "true" [tableRowData]="scpList" [tableTotalRecords]="totalRecords"
            (pageChange)="pageChange($event)" (CustomActionParam)="onCustomAction($event)"
            (actionParam)="editRole($event)" (sortChange)="sortColumn($event)"
            [tableCustomActionPermissions]="actionPermissions">
        </custom-table>
    </div>
</mat-card-content>