import { Component } from '@angular/core';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  staticText: any = (textConfiguration as any).default;
  currentYear: number = new Date().getFullYear();
}
