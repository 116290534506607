import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  MENU_UNIQ_NAME,
  routePath,
  USERGROUPS,
} from 'src/app/core/services/utils/constants';
import { DataService } from 'src/app/shared/services/data/data.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnChanges, OnDestroy {
  @Input() menuMode: string = 'standard';
  @Input() isViewInSmaller: boolean = false;
  @Input() sideNav: any;

  staticText: any = TranslationService.staticTextData;
  menuList: any[] = [];
  toggleMenuStatus: boolean[] = [];
  showMenuToggle: boolean = false;
  currentUrl: string = '';
  showCollapseState: boolean = true;
  isPopupVisible = false;

  private routeSubscription: Subscription | undefined;

  constructor(
    private route: Router,
    public commonService: CommonService,
    private dataService: DataService,
    private storageService: StorageService
  ) {
    this.menuList = this.commonService?.sideNavMenu || [];

    this.toggleMenuStatus = new Array(this.menuList.length).fill(false);
    this.getCurrentUrl();
  }

  ngOnInit(): void {
    this.commonService.fetchPermssions();
  }

  ngOnChanges(): void {
    this.showMenuToggle = this.isViewInSmaller;
  }

  sideNavToggle(): void {
    this.sideNav?.toggle();
    this.dataService.chartRender.next(true);
  }

  toggleSidenav(): void {
    this.showCollapseState = !this.showCollapseState;
  }

  redirect(menu: any): void {
    if (menu?.router) {
      const userDetails: any = this.storageService.getUserDetails();
      const isSCPAdmin =
        userDetails?.groups?.[0]?.name ===
        USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR;

      if (
        (menu.uniq_name === MENU_UNIQ_NAME.REGISTRATION_SCP ||
          menu.uniq_name === MENU_UNIQ_NAME.ONBOARD_SCP) &&
        isSCPAdmin
      ) {
        this.route.navigate([routePath?.SCP_APPLICATION_PATH]);
      } else {
        this.route.navigate([menu?.router]);
      }
    }
  }

  private catchMenu(menu: any[], uniq_name: string): any {
    return menu?.find((x: any) => x.uniq_name === uniq_name) || null;
  }

  private getCurrentUrl(): void {
    this.routeSubscription = this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.collapseAllMenu(this.menuList);
        this.inactiveAllMenu(this.menuList);
        let menuMap = new Map<string, any>();
        this.buildMenuMap(this.menuList, menuMap);

        const isActivated = this.activateMenuByRouter(
          this.menuList,
          this.currentUrl,
          menuMap
        );

        if (!isActivated) {
          console.warn('No matching route found in menu list.');
        }
      });
  }

  private activateMenuByRouter(
    menuList: any[],
    currentUrl: string,
    menuMap: Map<string, any>
  ): boolean {
    // Find the parent menu based on the parent path
    const parentMenu = this.findParentMenu(menuList, currentUrl);

    if (parentMenu) {
      // Activate the parent menu
      parentMenu.activate = true;
      parentMenu.expand = true;

      // Activate parent menus recursively
      this.activateParentMenus(parentMenu, menuMap);

      return true;
    }

    return false;
  }

  /**
   *  Find the parent menu based on the parent path
   */
  private findParentMenu(menuList: any[], parentPath: string): any {
    for (let menu of menuList) {
      if (
        this.isMatchingRoute(
          [menu?.router, ...(menu?.dependentRoutes || [])],
          parentPath
        )
      ) {
        return menu;
      }

      // Check recursively in children if no direct match
      if (menu.children && menu.children.length > 0) {
        const found = this.findParentMenu(menu.children, parentPath);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  /**
   *  Activate parent menus recursively based on parentUniqName.
   */
  private activateParentMenus(menu: any, menuMap: Map<string, any>): void {
    if (!menu.parentUniqName) return;

    // Get the parent menu using parentUniqName (using uniqueId)
    const parentMenu = menuMap.get(menu.parentUniqName);
    if (parentMenu) {
      // Activate parent menu
      parentMenu.activate = true;
      parentMenu.expand = true;

      // Recursively activate the parent of the parent (if parent has parentUniqName)
      this.activateParentMenus(parentMenu, menuMap);
    }
  }

  /**
   *  Matches route or child route (e.g., create, edit, view) and removes `/list` part.
   */
  private isMatchingRoute(
    menuRoutes: string | string[],
    currentUrl: string
  ): boolean {
    if (!menuRoutes || !currentUrl) return false;

    // Normalize current URL
    let sanitizedCurrentUrl = currentUrl.trim();

    // Remove leading slashes
    if (sanitizedCurrentUrl.startsWith('/')) {
      sanitizedCurrentUrl = sanitizedCurrentUrl.slice(1);
    }

    // Remove trailing slashes
    if (sanitizedCurrentUrl.endsWith('/')) {
      sanitizedCurrentUrl = sanitizedCurrentUrl.slice(0, -1);
    }

    // Convert menuRoutes into an array if it's a single string
    const routes = Array.isArray(menuRoutes) ? menuRoutes : [menuRoutes];
    return routes.some((route) => {
      if (!route) return false;

      let sanitizedRoute = route.trim();

      if (sanitizedRoute.startsWith('/')) {
        sanitizedRoute = sanitizedRoute.slice(1);
      }

      if (sanitizedRoute.endsWith('/')) {
        sanitizedRoute = sanitizedRoute.slice(0, -1);
      }

      if (sanitizedRoute.endsWith('/list')) {
        sanitizedRoute = sanitizedRoute.slice(0, -5);
      }

      // Check for an exact match or prefix match (for child routes)
      return (
        sanitizedRoute === sanitizedCurrentUrl ||
        sanitizedCurrentUrl.startsWith(sanitizedRoute + '/')
      );
    });
  }

  /**
   *  Recursively builds a menu map for quick lookup.
   */
  private buildMenuMap(menuList: any[], menuMap: Map<string, any>): void {
    menuList.forEach((menu) => {
      if (menu.uniq_name) {
        menuMap.set(menu.uniq_name, menu);
      }

      if (menu.children && menu.children.length > 0) {
        this.buildMenuMap(menu.children, menuMap);
      }
    });
  }

  private inactiveAllMenu(menuList: any[]): void {
    menuList.forEach((menu) => {
      menu.activate = false;
      if (menu.children?.length) {
        this.inactiveAllMenu(menu.children);
      }
    });
  }

  private collapseAllMenu(menuList: any[]): void {
    menuList.forEach((menu) => {
      menu.expand = false;
      if (menu.children?.length) {
        this.collapseAllMenu(menu.children);
      }
    });
  }

  private menuActivate(parentData: any, active: boolean): void {
    Object.keys(parentData).forEach((key) => {
      const menu = parentData[key];
      if (menu) {
        menu.expand = true;
        if (active) {
          menu.activate = true;
        }
      }
    });
  }

  handleMenuClick(event: Event, menu: any, position: number): void {
    this.toggleMenu(menu);
    this.redirect(menu);
  }

  private toggleMenu(menu: any): void {
    menu.expand = !menu.expand;
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    localStorage.removeItem('activeMenu');
  }

  togglePopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }
}
