import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { CustomTableFilterComponent } from 'src/app/shared/components/custom-table/custom-table-filter/custom-table-filter.component';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MasterService } from 'src/app/state/master/master.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { ApiMethod, Endpoints, FORMAT, PagePermssions, scp } from 'src/app/core/services/utils/constants';
import { forkJoin, Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-scp-list',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scp-list.component.html',
  styleUrl: './scp-list.component.scss'
})
export class ScpListComponent {
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;
  @Input() searchtext: string = '';
  public destroy = new Subject<void>();
  totalRecords = 0;
  staticText: any = (textConfiguration as any).default;
  scpEnum: any = scp;
  scpList: any[] = [];
  startRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  columnDefs: any = this.staticText?.scp?.register?.list?.table_fields;
  filterParamValues: any;
  tableFields: any;
  permission: any = PagePermssions
  actionPermissions = {};

  tableHeader: any = {
    multi: false,
    model: false,
    multiSelect: false
  };
  filterSource: any = null;
  filterKeys: any = null;

  data: any[] | undefined;
  searchForm: FormGroup | any;
  statusList: any;
  licenseList: any;
  pageInfo: any = {};
  constructor(private _http: HttpService,
    private _common: CommonService,
    public _routeService: RouterService,
    private _loader: LoaderService,
    private _modal: ModalService,
    private _lodash: LodashService,
    private _tableSerivce: CustomTableService,
    private formbuilder: FormBuilder,
    private activated_route: ActivatedRoute,
    private breadcrumbService: BreadCrumbService,
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.pageInfo = this.activated_route?.data;
    if (this.pageInfo.value.mode == this.scpEnum.registration) {
      this.tableFields = this.staticText?.scp?.register?.list?.table_fields;
      this.filterSource = this.staticText?.scp?.register?.list?.table_top_filter;
      this.filterKeys = [
        { key: "license_type", source: [], sourceKey: 'id', display_key: 'display_name' },
        { key: "status", source: [], sourceKey: 'id', display_key: 'action_complete_label' },
        { key: "date_range" },
      ];
      this.actionPermissions = {
        view: [this.permission.VIEW_SCP],
        edit: [this.permission.CHANGE_SCP],
        delete: [""]
      };
    } else if (this.pageInfo.value.mode == this.scpEnum.onboard) {
      this.tableFields = this.staticText?.scp?.register?.list?.onboarding_table_fields;
      this.filterSource = this.staticText?.scp?.register?.list?.table_top_filter;
      this.filterKeys = [
        { key: "license_type", source: [], sourceKey: 'id', display_key: 'display_name' },
        { key: "status", source: [], sourceKey: 'id', display_key: 'action_complete_label' },
        { key: "date_range" },
      ];
      this.actionPermissions = {
        view: [this.permission.VIEW_ONBOARD],
        edit: [this.permission.CHANGE_ONBOARD],
        delete: [""]
      };
    } else if (this.pageInfo.value.mode == this.scpEnum.scp_list) {
      this.tableFields = this.staticText?.scp?.register?.list?.scp_list_table_fields;
      this.filterSource = this.staticText?.scp?.register?.list?.scp_list_table_top_filter;
      this.filterKeys = [
        { key: "company", source: [], sourceKey: 'company_id', display_key: 'company_name' },
        { key: "license_type", source: [], sourceKey: 'id', display_key: 'display_name' },
        { key: "date_range" },
      ];
      this.actionPermissions = {
        view: [this.permission.VIEW_ONBOARD],
        edit: [""],
        delete: [""]
      };
      this.getCompany();
    }

    this.initialDependencies();
    //this.fetchAllDropdownData(); 
    this.getScpStatusList();
    this.getLicense();
    this.breadcrumbService.breadCrumbView(false);
  }

  ngOnChanges(changes: SimpleChanges | any) {
    // if (!changes.searchtext.firstChange)
    //   this.searchKeyword(this.searchtext);
  }

  getColumnSearch(): any {
    return this.cTable?.searchObj;
    // let searchObj:any = {};
    // let params = new URLSearchParams(searchObj);
    // return params.toString()
  }

  keyWordSearchFilter(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...{ search: ev }, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  searchKeyword(data: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...{ search: this.searchtext } };
    this.getScpList(this._common.filterParamsConstructor(param, this.startRow, this.endRow));
  }

  getSearchFormVal() {
    return (this.searchForm && this.searchForm.get('keyword').value) || '';
  }

  loadRoleList() {
    this.keyWordSearchFilter(this.getSearchFormVal());
  }

  initialDependencies() {
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    if (!this.searchtext) {
      this.loadRoleList();
    }
    // this.getScpStatusList();

    this.initiateForm()
  }

  initiateForm() {
    this.searchForm = this.formbuilder.group({
      keyword: ['']
    })
  }

  // getScpStatusList() {
  //   this.filterKeys[0]['source'] = this.roleFilterStatusData;
  //   this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
  // }

  resetPagination() {
    this._tableSerivce.currentPage = 1;
    this.startRow = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
    this.endRow = this._tableSerivce.pageSize || this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  }

  filterParams(data?: any) {
    this.resetPagination();
    this.filterParamValues = data?.value;
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }

  sortColumn(data: any) {
    let param: any = { ...this.filterParamValues, ...data, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result, true);
  }

  pageChange(evt: any) {
    this.startRow = evt?.startValue;
    this.endRow = evt?.endValue;
    console.log('pageChange: ', evt, this.startRow, this.endRow, this.filterParamValues)
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow,
    );
    this.getScpList(result);
  }

  onChangeActiveDropdown(selectedVal: any, fieldName: any, data: any) {
    let clonedRoleList = this._lodash.Clonedata(this.scpList)
    let matchedIndex = this._lodash.findIndexBy(this.scpList, 'id', data.id);
    if (matchedIndex > -1) {
      this.scpList[matchedIndex][fieldName] = selectedVal == 'True' || selectedVal == 'true' || selectedVal == true ? true : false;
      this.updateRoleStatus(this.scpList[matchedIndex], clonedRoleList);
    } else {
      this.resetList(clonedRoleList);
    }
  }

  updateRoleStatus(roleObj: any, clonedRoleList: any) {
    this._modal
      .openWarningDialog({
        data: {
          paragraph: `<p class="fs-14">Selected roles are mapped to users and will be no longer active.</p>`,
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this._loader.show();
          let endpoint: any = Endpoints.GET_SCP_LIST + roleObj.id + '/';
          let payload = {
            is_active: roleObj.is_active
          }
          this._http.requestCall(endpoint, ApiMethod.PATCH, payload).subscribe((response: any) => {
            this.resetList(this.scpList);
            this._loader.hide();
          }, (error: any) => {
            this.resetList(clonedRoleList);
            this._loader.hide();
          });
        } else {
          this.resetList(clonedRoleList);
        }
      })
  }

  resetList(list: any) {
    this.scpList = list;
    this.cTable.refreshTableData(list, false, this.startRow);
  }

  // updateCustomTableConfig() {
  //   this.tableFields['is_active'] = {
  //     ...this.tableFields['is_active'],
  //     "dropdown_config": {
  //       "options": [{
  //         "key": "ACTIVE",
  //         "value": true
  //       }, {
  //         "key": "INACTIVE",
  //         "value": false
  //       }],
  //       "className": "active-inactive-dropdown",
  //       "events": {
  //         "change": this.onChangeActiveDropdown.bind(this),
  //         "click": () => { }
  //       }
  //     }
  //   }
  // }

  buildSitePopover(data: any):any{
    let listOfSites = ``;
    if (!data?.sites?.length)
      return listOfSites;
    for (let index = 0; index < data?.sites.length; index++) {
      const element = data?.sites[index];
      listOfSites += `<span class="site-list-container">${element}</span><br>`;
    }
    return `<div>${listOfSites}</div>`

  }

  buildSitetemplate(data: any) {
    let listOfSites = ``;
    if (!data?.sites?.length)
      return listOfSites;

    return `<span>${data?.sites?.length} sites</span>` +
      `<span class="site-ellipsis position-relative align-items-center cursor-pointer d-inline-flex mx-3">
            <i class="fa fa-ellipsis"></i>
          </span>`;
  }


  getScpList(params?: any, sorting: boolean = false) {
    this._loader.show();
    let endpoint: any = "";
    if (this.pageInfo.value.mode == this.scpEnum.registration) {
      endpoint = Endpoints.GET_SCP_LIST + params;
    } else if (this.pageInfo.value.mode == this.scpEnum.onboard) {
      endpoint = Endpoints.ONBOARD_SCP_USER + params
    } else if (this.pageInfo.value.mode == this.scpEnum.scp_list) {
      endpoint = Endpoints.ONBOARD_SCP_USER + `${params}&is_scp_list=true`
    } else
      return
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((apiResponse: any) => {
      let response = apiResponse.data || {};
      this._loader.hide();
      this.totalRecords = response?.total_records;
      this.scpList = response?.records?.map((data: any) => ({

        ...data,
        "tin": data?.tin_number,
        "business_site": this.buildSitetemplate(data),
        "business_site_template": this.buildSitePopover(data),
        "registration_code": (data?.registration_code || data?.code) || '',
        "company_name": data?.company_name,
        "created_by": data?.created_by || '',
        "created_on": this.datePipe?.transform(data.created_on, FORMAT.DATE_TIME),
        "last_updated_on": this.datePipe?.transform(data.created_on, FORMAT.DATE_TIME),
        "license_type": (data?.license_type?.display_name ?? data?.license_type_display_name) ?? '',
        //"last_modified_on": this.datePipe?.transform(data.last_updated_on, FORMAT.DATE_TIME) ?? "-",        
        status: {
          color_code: data?.status?.color_code || '#000',
          text_color_code: data?.status?.text_color_code || '#FFFFFF',
          label: data?.status?.action_complete_label || '-',
          class: "status_align"
        },
        "id": this.pageInfo.value.mode == this.scpEnum.registration ? data?.id : data?.company,
        "route": [{ mode: 'delete', path: data.id, status: true }]
      }));

      this.cTable.setTableTotalRecordsCnt(this.totalRecords);
      this.cTable.refreshTableData(this.scpList, sorting, this.startRow);

      this._common.mapSerialNumberAndRoutePath((this['startRow'] || 0),
        this.scpList,
        [
          {
            status: true,
            route: '/users/view/',
            routeStatus: false,
            tooltip: 'view'
          },
          {
            status: true,
            mode: 'view',
            route: 'edit/',
            tooltip: 'view',
            routeStatus: false,
            icon: 'view_action',
          },
          { status: true, route: 'edit', mode: 'edit', routeStatus: false, tooltip: 'edit', modalStatus: true },
        ],
        false
      )

      this.scpList?.forEach((element: any) => {
        element.route?.forEach((routeData: any) => {
          if (routeData?.mode === "edit")
            routeData['hidden'] = !element?.allow_edit;
          if (routeData?.mode === "delete")
            routeData['hidden'] = false;
          if (routeData?.icon == 'view_action')
            routeData['hidden'] = false;
        });
      });

    });
  }

  onCustomAction(actionData: any) {
    //this._routeService.navigatePages('/users/role/view/'+actionData['Data']['id']);
  }

  editRole(ev: any) {
    if (ev?.action?.mode == 'view' || ev?.action?.icon == 'view_action') {
      if (this.pageInfo.value.mode == this.scpEnum.registration)
        this._routeService.navigatePages('/scp-mgmt/view-status/' + ev.Data.id + '/1/');
      else if (this.pageInfo.value.mode == this.scpEnum.onboard)
        this._routeService.navigatePages('/scp-mgmt/view-onboarding-status/' + ev.Data.id + '/2/');
      else if (this.pageInfo.value.mode == this.scpEnum.scp_list)
        this._routeService.navigatePages('/scp-mgmt/view-onboarding-status/' + ev.Data.id + '/3/');
    }
    else {
      if (this.pageInfo.value.mode == this.scpEnum.registration)
        this._routeService.navigatePages('/scp-mgmt/edit-registration/' + ev.Data.id + '/');
      else if (this.pageInfo.value.mode == this.scpEnum.onboard)
        this._routeService.navigatePages('/scp-mgmt/onboard-scp/' + ev.Data.id + '/');
      else if (this.pageInfo.value.mode == this.scpEnum.scp_list)
        this._routeService.navigatePages('/scp-mgmt/onboard-scp/' + ev.Data.id + '/');
    }
  }

  /**
  * @description
  * Table column wise search emitter...............
  * @param ev 
  */
  columnSearch(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ... this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getScpList(result);
  }


  /**DROPDOWN API USING  */

  getCompany() {
    let endpoint: any = "";
    endpoint = Endpoints.GET_DROPDOWN_COMPANY;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.filterKeys[0]['source'] = response?.data || []
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }


  getScpStatusList() {
    let endpoint: any = "";
    if (this.pageInfo.value.mode == this.scpEnum.registration) {
      endpoint = Endpoints.GET_DROPDOWN_STATUS;
    } else if (this.pageInfo.value.mode == this.scpEnum.onboard) {
      endpoint = Endpoints.GET_ONBOARD_DROPDOWN_STATUS;
    } else if (this.pageInfo.value.mode == this.scpEnum.scp_list) {
      return
    }
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.statusList = response?.data || [];
      this.filterKeys[1]['source'] = this.statusList.map((status: any) => ({
        id: status.id,
        slug: status.slug,
        action_complete_label: status.action_complete_label
      }));
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }

  getLicense() {
    let endpoint: any = Endpoints.GET_DROPDOWN_LICENSE;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.licenseList = response?.data || [];
      console.log(this.licenseList);
      if (this.pageInfo.value.mode == this.scpEnum.registration ||
        this.pageInfo.value.mode == this.scpEnum.onboard
      ) {
        this.filterKeys[0]['source'] = this.licenseList
      } else {
        this.filterKeys[1]['source'] = this.licenseList
      }
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }


}
