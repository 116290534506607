import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import {
  ApiMethod,
  Endpoints,
  PagePermssions,
  registration,
} from 'src/app/core/services/utils/constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { InitiateOnboardingComponent } from '../initiate-onboarding/initiate-onboarding.component';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { OnboardingService } from 'src/app/core/services/registration/onboarding.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-view-application',
  standalone: true,
  imports: [CommonModule, SharedModule, NgxPermissionsModule],
  templateUrl: './view-application.component.html',
  styleUrl: './view-application.component.scss',
})
export class ViewApplicationComponent implements OnInit, OnDestroy {
  staticText: any = TranslationService.staticTextData;
  permission: any = PagePermssions;
  scpDetails: any = {};

  registrationSlug: any = registration;
  subscriptions!: Subscription;

  constructor(
    private _modal: ModalService,
    private _http: HttpService,
    private _loader: LoaderService,
    public _routeService: RouterService,
    private _common: CommonService,
    private activated_route: ActivatedRoute,
    private _onBoard: OnboardingService
  ) {}

  ngOnInit(): void {
    // this._common.getSetRouteValue(this.activated_route?.data);
    this.getScpList();
    this.subscriptions = this._common.refreshScpProfile$.subscribe(
      (status: boolean) => {
        this.getScpList();
      }
    );
  }

  getScpList(params?: any, sorting: boolean = false) {
    this._loader.show();
    let endpoint: any = Endpoints.GET_SCP_LIST;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((apiResponse: any) => {
        this._loader.hide();
        this.scpDetails = apiResponse.data || {};
        this._onBoard.scpInfo = apiResponse.data?.records?.[0];
      });
  }

  navigateView(ev: any) {
    if (this.scpDetails?.records?.[0].onboard_exists)
      this._routeService.navigatePages(
        '/scp-mgmt/view-onboarding-status/' + ev.id + '/'
      );
    else
      this._routeService.navigatePages('/scp-mgmt/view-status/' + ev.id + '/');
  }

  navigateToOnboard(ev: any) {
    this._routeService.navigatePages('/scp-mgmt/onboard-scp/' + ev.id + '/');
  }

  initiateOnboarding(ev: any) {
    this._modal
      .openCommonDialog({
        width: '700px',
        height: '95%',
        component: InitiateOnboardingComponent,
        data: {
          title: this.staticText?.scp?.view_register?.initiate_title,
          proceedBtnTxt:
            this.staticText?.scp?.view_register?.proceed_onboarding_process,
          instruction: [
            this.staticText?.scp?.view_register?.initiate_detail_field1,
            this.staticText?.scp?.view_register?.initiate_detail_field2,
            this.staticText?.scp?.view_register?.initiate_detail_field3,
            this.staticText?.scp?.view_register?.initiate_detail_field4,
          ],
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result !== false) {
          this.navigateToOnboard(ev);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
