import { FormSection, percentageValidator, numberValidator, minDate, maxDate, noUnderscoreValidator, requiredValidator, maxLengthValidator, hiddenIdField } from 'src/app/shared/components/dynamic-form/form-sections.model'; // Adjust the path as needed
import { Validators } from '@angular/forms';
import { Endpoints } from 'src/app/core/services/utils/constants';
const locationField = {
    type: 'location_field',
    label: 'Location',
    name: 'location',
    meta: { 'form_type': 'add', 'config': { 'required': true, 'multiSelect': false } }
};

export const FORM_SECTIONS: FormSection[] = [
    {
        title: 'Business Information',
        key: 'business_info',
        sectionType: 'single',
        columns: 4, // Number of columns per row
        meta: { viewConfig: { visible: false } },
        fields: [
            hiddenIdField,
            {
                type: 'text',
                label: 'Company Name',
                name: 'name',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Company Name must be no longer than 250 characters.'),
                    // { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Company Name can only contain letters and numbers' }
                ]
            },
            {
                type: 'select',
                label: 'License Type',
                name: 'license_type',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_LICENSE_TYPE
            },
            {
                type: 'date',
                label: 'Date of Incorporation',
                name: 'date_of_incorporation',
                labelReq: false,
                validators: [
                    requiredValidator,
                    { name: 'max', validator: maxDate(new Date()), message: 'The date cannot be greater than today.' }
                ],
                meta: {
                    'max_date': new Date() // , 'min_date': new Date(new Date().setDate(new Date().getDate() - 900))
                }
            },
            {
                type: 'select',
                label: 'Company Ownership',
                name: 'ownership',
                validators: [
                    requiredValidator
                ],
                apiEndpoint: Endpoints.GET_OWNERSHIP_TYPE,
                meta: {
                    'controlSection': {
                        controlValue: 'individual',
                        sectionKeys: ['ownership_info'],
                        actions: ['clear']
                    }
                }
            },
            {
                type: 'text',
                label: 'Company Email ID',
                name: 'email',
                validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Company Contact Number',
                name: 'contact_no',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Company Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Company Contact Number can only contain numbers' }
                ]
            },
            {
                type: 'textarea',
                label: 'Company Address',
                name: 'address',
                validators: [
                    requiredValidator,
                    maxLengthValidator(500, 'Company Address must be no longer than 500 characters.')

                ],
                meta: { viewConfig: { colspan: 3 } }
            },
            locationField
        ],
    },
    {
        title: 'Registration Information',
        key: 'certificate_info',
        columns: 4,
        sectionType: 'multiple',
        meta: {
            viewColumns: 4, hideActionBtn: true, renderDataOnload: true, uniqueKey: 'certificate', dataAPIEndpoint: Endpoints.GET_REGISTRATION_CERTIFICATES,
            data: [], withBorder: true
        },
        fields: [
            {
                type: 'hidden',
                label: 'Certificate / License',
                name: 'certificate',
                labelReq: false,
                visible: false

            },
            {
                type: 'hidden',
                label: 'Certificate / License',
                name: 'id',
                validators: [
                    requiredValidator

                ],
                labelReq: true,
                meta: { viewConfig: { showSectionTitle: true, sectionField: 'certificate' } }
            },
            {
                type: 'text',
                label: 'Certificate Number',
                name: 'certificate_number',
                validators: [
                    requiredValidator,
                    maxLengthValidator(100, 'Certificate Number must be no longer than 100 characters.')

                ]
            },
            {
                type: 'date',
                label: 'Expiry Date',
                name: 'expiry_date',
                labelReq: false,
                validators: [
                    requiredValidator,
                    { name: 'min', validator: minDate(new Date()), message: 'The date cannot be earlier than today.' }

                ],
                meta: {
                    'min_date': new Date()
                }
            },
            {
                type: 'hidden',
                label: 'Status',
                name: 'certificate_status',
                labelReq: false,
                meta: { viewConfig: { colorRefField: 'color_code' } }
            },
            {
                type: 'hidden',
                label: 'Color Code',
                name: 'color_code',
                visible: false
            },
            {
                type: 'multi_file',
                label: 'Upload Certificate Copy',
                labelReq: false,
                name: 'attachments',
                validators: [
                    requiredValidator,

                ]
            },
            {
                type: 'checkbox',
                label: 'Not Applicable',
                labelReq: true,
                name: 'is_not_applicable',
                class: 'is-not-applicable',
                controlsDisabling: true,
                validators: [

                ],
                meta: { 'excludeFields': ['is_not_applicable', 'id', 'certificate'] }
            }
        ],
    },
    {
        title: 'Ownership Information',
        key: 'ownership_info',
        columns: 2,
        sectionType: 'multiple', // This section allows multiple entries
        meta: { 'inlineEdit': false, btnText: 'Add Ownership', triggerOverallPercentageCalculation: true, disableActionBtn: false, controlValue: 'individual', },
        layout: 'table',
        fields: [
            {
                type: 'hidden',
                label: 'Owner ID',
                name: 'id',
                validators: [
                ],
                labelReq: false,
                visible: false
            },
            {
                type: 'text', label: 'Ownership Name', name: 'name', validators: [requiredValidator,
                    maxLengthValidator(150, 'Ownership Name must be no longer than 150 characters.'),


                ]
            },
            {
                type: 'text', label: 'Email ID', name: 'email', validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }]
            },
            {
                type: 'phone_field', altName: 'country_code', label: 'Contact Number', name: 'contact_no', validators: [
                    requiredValidator,
                    { name: 'number', validator: numberValidator(), message: 'Enter a valid number.' }
                ]
            },
            {
                type: 'text', label: 'Percentage of Ownership', name: 'ownership_percentage', validators: [
                    requiredValidator,
                    { name: 'percentage', validator: percentageValidator(), message: 'Enter a valid percentage (0-100).' },
                    // { name: 'percentageExceeded', validator: ownershipPercentageValidator('ownership_percentage'), message: 'Overall percentage should not exceed.' },

                ],
                meta: { inputSuffixText: "%" },
            },
        ],
    }
];

export const ONBOARD_SITEINFO_FORM_SECTIONS: FormSection[] = [
    {
        title: 'Site Information',
        key: 'site_details',
        sectionType: 'single',
        columns: 4, // Number of columns per row
        meta: { isEmptyCheck: true },
        fields: [
            hiddenIdField,
            {
                type: 'hidden',
                label: 'Company ID',
                name: 'company',
                labelReq: false,
                visible: false
            },
            {
                type: 'text',
                label: 'Site Name',
                name: 'name',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Site Name must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'text',
                label: 'Facility Type',
                name: 'facility_type',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Facility Type must be no longer than 250 characters.'),

                ]
            },
            {
                type: 'text',
                label: 'Email ID',
                name: 'email',
                validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Contact Number',
                name: 'contact_no',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Site Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Site Contact Number can only contain letters and numbers' }
                ]
            },
            {
                type: 'text',
                label: 'Site Address',
                name: 'address',
                validators: [
                    requiredValidator,
                    maxLengthValidator(500, 'Site Address must be no longer than 500 characters.'),

                ],
                meta: { viewConfig: { colspan: 2 } }
            },
            /*    {
                    type: 'select',
                    label: 'Location',
                    name: 'location',
                    options: [],
                    apiEndpoint: Endpoints.GET_CHILD_LOCATION_LIST,
                    validators: [
                        requiredValidator
                    ],
                    meta: { 'ondemand': true, 'ondemandSearchKey': 'search' }
                } */
            locationField
        ],
    },
    {
        title: 'Billing Address',
        key: 'billing_address',
        sectionType: 'single',
        columns: 4, // Number of columns per row
        meta: {
            isEmptyCheck: true,
            copyDataFrom: {
                options: [],
                key: 'copy_billing_address',
                selectionChangeHandler: null,
                type: 'checkbox',
                label: 'Same address data from site address'
            }
        },
        fields: [
            hiddenIdField,
            {
                type: 'text',
                label: 'Email ID',
                name: 'email',
                validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Contact Number',
                name: 'contact_no',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Site Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Site Contact Number can only contain letters and numbers' }
                ]
            },
            {
                type: 'text',
                label: 'Site Address',
                name: 'address',
                validators: [
                    requiredValidator,
                    maxLengthValidator(500, 'Site Address must be no longer than 500 characters.'),

                ],
                meta: { viewConfig: { colspan: 2 } }
            },
            locationField
        ]
    },
    {
        title: 'Shipping Address',
        key: 'shipping_address',
        sectionType: 'single',
        columns: 4, // Number of columns per row
        meta: {
            isEmptyCheck: true,
            copyDataFrom: {
                options: [],
                key: 'copy_shipping_address',
                selectionChangeHandler: null,
                type: 'checkbox',
                label: 'Same address data from site address'
            }
        },
        fields: [
            hiddenIdField,
            {
                type: 'text',
                label: 'Email ID',
                name: 'email',
                validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Contact Number',
                name: 'contact_no',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Site Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Site Contact Number can only contain letters and numbers' }
                ]
            },
            {
                type: 'text',
                label: 'Site Address',
                name: 'address',
                validators: [
                    requiredValidator,
                    maxLengthValidator(500, 'Site Address must be no longer than 500 characters.'),

                ],
                meta: { viewConfig: { colspan: 2 } }
            },
            locationField
        ]
    },
    {
        title: 'Production Information',
        key: 'production_details',
        columns: 2,
        sectionType: 'multiple', // This section allows multiple entries
        meta: {
            'inlineEdit': false, btnText: 'Add Production Information', btnActionText: " Production", viewConfig: { action: true },
            /* copyDataFrom: {
                 options: [],
                 key: 'copy_from_production',
                 selectionChangeHandler: null
             } */
        },
        layout: 'table',
        fields: [
            {
                type: 'hidden',
                label: 'Production ID',
                name: 'id',
                labelReq: false,
                visible: false
            }, {
                type: 'hidden',
                label: 'Is Copy',
                name: 'is_copy',
                labelReq: false,
                visible: false
            },
            {
                type: 'text', label: 'Machine Name', name: 'machine_name', validators: [requiredValidator,
                    maxLengthValidator(250, 'Machine Name must be no longer than 250 characters.')]
            },
            {
                type: 'text', label: 'Machine Number', name: 'machine_number', validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Machine Number must be no longer than 250 characters.')]
            },
            {
                type: 'text',
                label: 'Machine Type',
                name: 'machine_type',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Machine Type must be no longer than 250 characters.')
                ]
            },
            {
                type: 'text', label: 'Production Line', name: 'production_line', validators: [
                    maxLengthValidator(250, 'Production Line must be no longer than 250 characters.')
                ]
            },
            {
                type: 'text', label: 'Max. Production Capacity', name: 'production_capacity', validators: [
                    maxLengthValidator(250, 'Max. Production Capacity must be no longer than 250 characters.')
                ], meta: { table_display: false }
            },
            {
                type: 'text', label: 'Avg. Production per Week', name: 'production_avg', validators: [
                    maxLengthValidator(250, 'Avg. Production per Week must be no longer than 250 characters.')
                ], meta: { table_display: false }
            },
            {
                type: 'textarea', columns: 1, label: 'Description', name: 'description', validators: [], meta: { table_display: false }
            }
        ],
    },
    {
        title: 'Product & Packaging Information',
        key: 'product_details',
        columns: 2,
        sectionType: 'multiple', // This section allows multiple entries
        meta: {
            inlineEdit: false, btnText: 'Add Product Information', btnActionText: " Product",
            viewConfig: { action: true },
            /*  copyDataFrom: {
                  options: [],
                  key: 'copy_from_product',
                  selectionChangeHandler: null
              } */
        },
        layout: 'table',
        fields: [
            {
                type: 'hidden',
                label: 'Product ID',
                name: 'id',
                labelReq: false,
                visible: false
            }, {
                type: 'hidden',
                label: 'Is Copy',
                name: 'is_copy',
                labelReq: false,
                visible: false
            },
            {
                type: 'text', label: 'Brand Name', name: 'brand_name', validators: [requiredValidator,
                    maxLengthValidator(250, 'Brand Name must be no longer than 250 characters.'),

                ]
            },
            {
                type: 'select',
                label: 'Product Type',
                name: 'product_type',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_PRODUCT_TYPE
            },
            {
                type: 'select',
                label: 'Product Category',
                name: 'category',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY,
                dependsOn: 'product_type',
            },
            {
                type: 'text', label: 'Product Name', name: 'display_name', validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Product Name must be no longer than 250 characters.'),
                    { name: 'noUnderscore', validator: noUnderscoreValidator(), message: 'Product Name cannot contain underscores.' },
                ]
            },
            {
                type: 'select',
                label: 'Type of Packaging',
                name: 'package',
                validators: [
                    requiredValidator
                ],
                options: [],
                dependsOn: 'product_type',
                apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY_PACKAGE, meta: { table_display: false }
            },
            {
                type: 'select',
                label: 'Volume & Unit',
                name: 'uom',
                dependsOn: 'product_type',
                meta: { table_display: false },
                validators: [
                    requiredValidator,
                ],
                apiEndpoint: Endpoints.GET_PRODUCT_VOLUME_UOM,
                options: []
            },
            {
                type: 'file',
                label: 'Upload Product Image',
                labelReq: false,
                name: 'attachments',
                validators: [
                    { name: 'required', validator: Validators.required, message: 'Product Image is required' },
                ],
                meta: { table_display: false, max_file_size: 5, allowed_type: '.jpeg, .jpg, .png' }
            },
            {
                type: 'textarea', label: 'Description', columns: 1, name: 'description', validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Product Description must be no longer than 250 characters.'),
                ], meta: { table_display: false }
            }
        ],
    }
];

export const ONBOARD_BANKINFO_FORM_SECTIONS: FormSection[] = [
    {
        title: 'Bank Information',
        key: 'bank_info',
        sectionType: 'single',
        columns: 4, // Number of columns per row
        meta: { hideSectionTitle: true },
        fields: [
            hiddenIdField,
            {
                type: 'hidden',
                label: 'Company ID',
                name: 'company',
                labelReq: false,
                visible: false
            },
            {
                type: 'text',
                label: 'Bank Name',
                name: 'bank_name',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Bank Name must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'text',
                label: 'Branch',
                name: 'branch',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Branch must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'text',
                label: 'Account Name',
                name: 'accountant_name',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Account name must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'text',
                label: 'Account Number',
                name: 'account_number',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Account Number must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'select',
                label: 'Account Type',
                name: 'account_type',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.ONBOARD_ACCOUNT_TYPE_DROPDOWN
            },
            {
                type: 'text',
                label: 'Swift / BIC Code',
                name: 'bic_code',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'Swift / BIC Code must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'text',
                label: 'IBAN',
                name: 'iban_number',
                validators: [
                    requiredValidator,
                    maxLengthValidator(250, 'IBAN must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'multi_file',
                label: 'Supportive Document (Optional)',
                labelReq: false,
                name: 'attachments',
                validators: [

                ]
            }
        ],
    }
];

export const ONBOARD_SUPPLYINFO_FORM_SECTIONS: FormSection[] = [
    {
        title: 'Supply Chain List',
        key: 'supplier_info',
        columns: 2,
        sectionType: 'multiple', // This section allows multiple entries
        meta: { 'inlineEdit': false, btnText: 'Add New Supply Chain', hideSectionTitle: true, viewConfig: { action: true } },
        layout: 'table',
        fields: [
            {
                type: 'hidden',
                label: 'Supply Chain ID',
                name: 'id',
                labelReq: false,
                visible: false
            },
            {
                type: 'text', label: 'Supplier Company Name', name: 'company_name', validators: [requiredValidator,
                    maxLengthValidator(250, 'Supplier Company Name must be no longer than 250 characters.'),
                ]
            },
            {
                type: 'select',
                label: 'License Type',
                name: 'license_type',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_LICENSE_TYPE
            },
            {
                type: 'text', label: 'Supplier Email ID', name: 'email', validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Contact Number',
                name: 'contact_no',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Contact Number can only contain letters and numbers' }
                ], meta: { table_display: false }
            },
            {
                type: 'text',
                label: 'Site Address',
                name: 'address',
                validators: [
                    requiredValidator,
                    { name: 'maxlength', validator: Validators.maxLength(500), message: 'Address must be no longer than 500 characters.' },

                ],
                meta: { viewConfig: { colspan: 2 }, table_display: false }
            },
            locationField,
            {
                type: 'select',

                label: 'Product Type',
                name: 'product_type',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_PRODUCT_TYPE,
                meta: { table_display: false }
            },
            {
                type: 'select',
                label: 'Product Category',
                name: 'product_category',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.GET_PRODUCT_CATEGORY,
                dependsOn: "product_type",
                meta: { table_display: false }
            },
            {
                type: 'select', labelReq: true, label: 'Product Name', name: 'product', validators: [
                    requiredValidator],
                options: [],
                apiEndpoint: Endpoints.GET_PRODUCT_DROPDOWN,
                dependsOn: "product_category",
                meta: { multiple: true }
            }
        ],
    }
];

export const ONBOARD_USERINFO_FORM_SECTIONS: FormSection[] = [
    {
        title: 'Users Information',
        key: 'user_info',
        columns: 2,
        sectionType: 'multiple', // This section allows multiple entries
        meta: { 'inlineEdit': false, btnText: 'Add New User', hideSectionTitle: true, viewConfig: { action: true } },
        layout: 'table',
        fields: [
            {
                type: 'hidden',
                label: 'User ID',
                name: 'id',
                labelReq: false,
                visible: false
            },
            {
                type: 'text', label: 'Name', name: 'first_name', validators: [requiredValidator,
                    { name: 'maxlength', validator: Validators.maxLength(150), message: 'Name must be no longer than 150 characters.' },

                ]
            },
            {
                type: 'text', label: 'Email ID', name: 'email', validators: [
                    requiredValidator,
                    { name: 'email', validator: Validators.email, message: 'Enter a valid email address' }
                ]
            },
            {
                type: 'phone_field',
                label: 'Contact Number',
                name: 'phone_number',
                altName: 'country_code',
                validators: [
                    requiredValidator,
                    { name: 'minlength', validator: Validators.minLength(3), message: 'Contact Number must be at least 3 characters long' },
                    { name: 'pattern', validator: Validators.pattern('^[a-zA-Z0-9]*$'), message: 'Contact Number can only contain letters and numbers' }
                ]
            },
            {
                type: 'select',
                label: 'Business Site',
                name: 'site',
                validators: [
                    requiredValidator
                ],
                options: [],
                apiEndpoint: Endpoints.ONBOARD_SITE_DROPDOWN
            }
        ],
    }
];

export const ONBOARD_OTHERINFO_FORM_SECTIONS: FormSection[] = [
    {
        title: 'Attachments',
        key: 'general_info',
        sectionType: 'root',
        columns: 4, // Number of columns per row
        meta: {},
        fields: [
            {
                type: 'multi_file',
                label: 'Upload Document',
                labelReq: false,
                name: 'attachments',
                validators: [
                ]
            }
        ],
    },
    {
        title: 'Digital Signature',
        key: 'general_info_signature',
        sectionType: 'root',
        columns: 4, // Number of columns per row
        meta: {},
        fields: [
            {
                type: 'file',
                label: 'Digital Signature',
                labelReq: false,
                name: 'digital_signature',
                validators: [
                    requiredValidator,
                ]
            }
        ],
    },
    {
        title: 'Remarks',
        key: 'general_info_remarks',
        sectionType: 'root',
        columns: 2, // Number of columns per row
        meta: {},
        fields: [
            {
                type: 'textarea',
                label: 'Remarks (Optional)',
                name: 'remarks',
                validators: [
                    { name: 'maxlength', validator: Validators.maxLength(500), message: 'Remarks must be no longer than 500 characters.' },
                ],
                meta: { viewConfig: { colspan: 2 } }
            }
        ],
    }
];