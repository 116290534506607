import { createReducer, on } from '@ngrx/store';
import { addDataToMaster, removeDataFromMaster, setMasterAPICompleteStatus, setMasterAPIProgressStatus, setMasterList, updateDataToMaster } from './master.action';
import { initialState } from './master.state';

const _masterReducer = createReducer(
  initialState,
  on(setMasterList, (state: any, action: any) => {
    console.log('setMasterList: ', action.data.key, state, action)
    return {
      ...state,
      [action.data.key]: {
        list: action.data.list,
        isInProgress: action.data.isInProgress,
        isLoaded: action.data.isLoaded
      }
    }
  }),
  on(addDataToMaster, (state: any, action: any) => {
    console.log('addDataToMaster: ', state, action)
    return {
      ...state,
      [action.data.key]: {
        list: [
          ...state[action.data.key]['list'],
          action.data.newData
        ]
      }
    }
  }),
  on(removeDataFromMaster, (state: any, action: any) => {
    console.log('removeDataFromMaster: ', state, action)
    return {
      ...state,
      [action.data.key]: {
        list: state[action.data.key]['list'].filter((t: any) => t.id !== action.data.deleteId)
      }
    }
  }),
  on(updateDataToMaster, (state: any, action: any) => {
    console.log('updateDataToMaster: ', state, action)
    return {
      ...state,
      [action.data.key]: {
        list: state[action.data.key]['list'].map((t: any) => (t.id === action.data.updateId ? action.data.updatedData : t))
      }
    }
  }),
  on(setMasterAPIProgressStatus, (state: any, action: any) => {
    // console.log('setMasterAPIProgressStatus: ', state, action)
    return {
      ...state,
      [action.data.key]: {
        ...state[action.data.key],
        isInProgress: action.data.isInProgress,
      }
    };
  }),
  on(setMasterAPICompleteStatus, (state: any, action: any) => {
    // console.log('setMasterAPICompleteStatus: ', state, action)
    return {
      ...state,
      [action.data.key]: {
        ...state[action.data.key],
        isLoaded: action.data.isLoaded,
      }
    };
  }),
  // on(reset, (state) => 0)
);

export function masterReducer(state: any = initialState, action: any) {
  return _masterReducer(state, action);
}