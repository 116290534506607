import { Injectable } from '@angular/core';
import { ApiMethod, Endpoints } from '../utils/constants';
import { DatePipe } from '@angular/common';
import { HttpService } from '../http/http.service';
import { LodashService } from '../lodash/lodash.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  scpInfo:any = {}; // LIst API details
  onboardingInfo:any = {};

  constructor(
    private _http: HttpService,
    private datePipe: DatePipe,
    private lodash: LodashService
  ) { }

  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getOnboard(id: any) {
    let endpoint: any = Endpoints.ONBOARD_SCP_USER + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET)
  }

  //.......................Get obboard details 
 
  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getSCP(id: any) {
    let endpoint: any = Endpoints.GET_SCP_LIST + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET)
  }

    //.......................Get obboard details 
 
  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getSCPRegistrationHeaderInfo(id: any) {
    let endpoint: any = Endpoints.GET_SCB_REGN_CARD_STATUS + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET)
  }

  //.......................Get obboard details 

  /**
   * @description
   * Save onboard details ............................
   * @param id current registration id
   * @param formData Payload details
   * @returns Return respective API response
   */

  saveDetails(id: any, formData: any) {
    let endpoint: any;
    endpoint = `${Endpoints.ONBOARD_PROCESS}${id}/`;
    return this._http.requestCall(endpoint, ApiMethod.POST, formData, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } })
  }

  //............................Save onboard details 

  /**
   * @description
   * Update onboard details ............................
   * @param id current registration id
   * @param formData Payload details
   * @returns Return respective API response
   */

  updateDetails(id: any, formData: any) {
    let endpoint: any;
    endpoint = `${Endpoints.ONBOARD_PROCESS}${id}/`;
    return this._http.requestCall(endpoint, ApiMethod.PUT, formData, { observe: 'response', headers: { 'Content-Type': 'multipart/form-data' } })
  }

  //............................Update onboard details 

  /**
   * @description
   * Get Next status for SCP onboard .................
   * @param id current registration id
   * @returns Return respective API response
   */

  getNextStatus(id: any): Observable<any> {
    let endpoint: any = Endpoints.ONBOARD_PROCESS_NEXT_STATUS + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  //.................Get Next status for SCP onboard 

  /**
   * Status change API .................................
   * @param formData 
   * @param currentId current registration id
   * @param nextActionName Next action 
   * @returns Return respective API response
   */

  finalSave(formData: any, currentId: any, nextActionName: string = '') {
    let endpoint: any =
      Endpoints.ONBOARD_PROCESS_STATUS_CHANGE + currentId + `/?status_key=${nextActionName}`;
    return this._http
      .requestCall(endpoint, ApiMethod.PUT, formData, {
        observe: 'response',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
  }

  //.................................Status change API 

}
