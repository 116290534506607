import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule, DatePipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { UnderDevelopmentComponent } from './modules/under-development/under-development.component';

import { StoreModule } from '@ngrx/store';
import { masterReducer } from './state/master/master.reducer';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { TopNavbarComponent } from './layout/top-navbar/top-navbar.component';



@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BaseLayoutComponent,
    TopNavbarComponent,
    UnderDevelopmentComponent
    
  ],
 
  imports: [
    MatFormFieldModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    StoreModule.forRoot({ 'master': masterReducer }),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DatePipe,
    TitleCasePipe,
    LowerCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }