<div class="onboarding-header">
    <h2 class="header-title w-75" mat-dialog-title>What you should know before starting the onboarding process</h2>

    <img class="title-card" src="../../../assets/images/common/icons/stamp.svg" alt="info" />

    <span class="close-dialog" (click)="close()">
        <i class="fa fa-close"></i>
    </span>
</div>

<mat-dialog-content class="mat-typography">
  <div class="pt-4">
    <ul class="onboarding_details">
        <li>Provide details about your business, including information related to your site, production (if applicable), and the products you offer.</li>
        <li>When sharing your bank information, include the bank name, branch, account holder’s name, and account number. Also, provide the IFSC or SWIFT code for transfers and mention the account type (savings or checking).</li>
        <li>Your supply chain information, include the supplier company name, supplier email ID, and license type. Additionally, mention the product or service provided, contract details (if any), and payment terms. Ensure to provide contact details for communication and any relevant certifications or compliance documents.</li>
        <li>Provide details about your Users, including information related to your Name,Email Id,Contact Number,etc.</li>
      </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="pb-4">
  <button class="onboard_btn mx-2" (click)="onboarding()">
    <span>Initiate Onboarding Process</span></button>
</mat-dialog-actions>
