<div class="right-action-section card_style m-4">

    <div class="title-card d-flex align-items-center">

        <div class="fs-20 fw-bold px-4 py-3 title title_width_registration">
            <span class="form-title">{{staticText?.comments?.title}}</span>
        </div>
    </div>

    <div class="px-4 py-2">
        <div class="">
            <div>
                <div class="container-box comments-container-box p-0">
                    <div class="comments-list-container">
                        @if(commentsList?.length){
                        @for(comment of commentsList;track $index){
                        <div class="my-2 p-4 bg-body-secondary view-section">
                            <div>
                                <div class="my-2">
                                    <label for="">
                                        <span class="fw-bold">{{comment?.role?.display_name}}</span>
                                        <span class="mx-2 fs-12"><i class="fa fa-circle-dot"></i></span>
                                        <span>{{comment?.created_on | date :'MMM dd, YYYY'}}</span>
                                        <span class="mx-2 fs-12"><i class="fa fa-circle-dot"></i></span>
                                        <span>{{comment?.created_on | date :'hh:mm a'}}</span>
                                    </label>
                                </div>
                                <div>
                                    <span>
                                        {{comment?.message}}
                                    </span>
                                </div>
                                @if(comment?.attachments?.length){
                                <div class="row d-flex">
                                    @for(attachment of comment?.attachments;track $index){
                                    <div class="file-container p-3">
                                        <div class="w-100 bg-white p-2 border-0 attachment-container">
                                            <div><span>{{staticText?.common?.attachment}}</span></div>
                                            <div class="eye-icn cursor-pointer" (click)="viewFilePreview(attachment)">
                                                <span class="color-Orient"><img
                                                        src="../../../../../assets/images/common/icons/view_table.svg"
                                                        alt=""></span>
                                            </div>
                                            <div class="d-flex">
                                                <label class="d-flex" for="">
                                                    <span class="color-Orient"><i class="fa fa-paperclip"></i></span>
                                                    <span class="mx-1"></span>
                                                    <div class="file-name">
                                                        <span>{{attachment?.name || attachment?.file_name}}</span>
                                                    </div>
                                                    <span class="mx-3"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        }
                        } @else{
                        <div class="no_record_comments">
                            <p class="text-center fs-16 fw-500 color-boulder-grey">
                                {{staticText?.common?.no_comments_found}}</p>
                        </div>
                        }
                    </div>

                    <div class="my-4" *ngxPermissionsOnly="[permission.add]">
                        <button class="next-btn create-default-style" mat-stroked-button (click)="addNewComments()">
                            <span [innerHtml]="staticText?.comments?.Add"></span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>