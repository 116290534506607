<nav class="navbar navbar-expand-lg">
    <div class="container-fluid justify_end_header">
        <!-- Toggle Button for Small Screens -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ng-container *ngIf="menuList?.length">
                <ul class="navbar-nav">
                    <ng-container *ngFor="let menu of menuList; index as menuIndex">
                        <li class="nav-item list_itm"
                            [ngClass]="{'active-menu': menu.activate, 'active-state': menu.activate && position === 1}"
                            *ngxPermissionsOnly="menu?.permission">
                            <ng-container
                                *ngTemplateOutlet="submenus; context: { $implicit:menu, index:menuIndex, position:1 }">
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
    </div>

    <!-- Submenu Template -->
    <ng-template #submenus let-menu let-idx="index" let-position="position">
        <ng-container>
            <div class="cursor-pointer text-capitalize pos-menu">
                <div class="inner-menu-box" (click)="menuClick($event, menu, position)" (click)="redirect(menu)">
                    <ng-container *ngIf="menu?.icon">
                        <div class="p-1 left-icon">
                            <img class="menus_image"
                                [src]="'../../../assets/images/layout/sidenav/icons/'+ menu?.icon +'.svg'"
                                [alt]="menu?.icon">
                        </div>
                    </ng-container>
                    <div class="menu-name">
                        <a href="javascript:void(0);" [routerLink]="menu?.router" (click)="redirect(menu)"
                            class="text-decoration-none">{{menu.name}}</a>
                    </div>
                </div>
            </div>

            <!-- Submenus (Dropdowns) -->
            <ng-container>
                <ul class="dropdown-menu dropdown_ul">
                    <ng-container *ngFor="let submenu of menu?.children; index as subParentIndex">
                        <li [ngClass]="{'active-menu': submenu.activate}" class="dropdown-item sub_menu_li" href="#"
                            *ngxPermissionsOnly="submenu?.permission">
                            <ng-container
                                *ngTemplateOutlet="submenus; context: { $implicit:submenu, index:subParentIndex, position:(position + 1) }">
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </ng-container>
    </ng-template>
</nav>
