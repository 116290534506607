
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader/loader.service';



@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() color: string = 'primary';
  @Input() background_color: string = 'rgba(74, 74, 74, .1)';
  @Input() mode: any = 'indeterminate' as const;

  showLoading: boolean = false;

  constructor(private _loaderService: LoaderService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._loaderService.showLoading.subscribe((data) => {
      this.showLoading = data;
      this.cdr.detectChanges();
    });
  }

  
}