import { Component } from '@angular/core';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';


@Component({
  selector: 'app-guest-header',
  standalone: true,
  imports: [],
  templateUrl: './guest-header.component.html',
  styleUrl: './guest-header.component.scss'
})
export class GuestHeaderComponent {
  staticText: any = (textConfiguration as any).default;

}
