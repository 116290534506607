<div class="notification-wrapper">
    <!-- Notification Icon -->
    <div class="notification-icon" [matMenuTriggerFor]="notification">
        <img src="/assets/images/common/icons_usp/notofication.svg" alt="Notifications" />
        <!-- Badge for unread notifications -->
        <span class="notification-badge" *ngIf="unreadCount > 0">{{ unreadCount }}</span>
    </div>

    <!-- Notification Popup -->
    <mat-menu #notification="matMenu" class="dialog-notification-wrapper">

        <div class="notification-popup">
            <div class=" notify_flx" (click)="$event.stopPropagation()">
                <h3 class="notify_txt mb-0">Notifications</h3>
                <div class="read-checkbox mx-1">


                    <div class="read-checkbox mx-1">
                        <mat-checkbox [(ngModel)]="onlyShowUnread" [color]="'primary'" (change)="filterNotifications()">
                            {{staticText?.common?.show_unread}}
                        </mat-checkbox>
                    </div>



                </div>
            </div>
            <div class="search" (click)="$event.stopPropagation()">
                <div class="input-wrapper">
                    <img src="/assets/images/common/icons/search_1.svg" alt="Search" class="search-icon" />
                    <input #inputNotificationBox type="text" [(ngModel)]="searchTerm" placeholder="Search" />
                </div>
            </div>

            <div class="notifications-list">
                @if(!loading){
                @if (notifications?.length) {
                <div *ngFor="let notification of notifications" class="notification-item cursor-pointer"
                    [ngClass]="{ 'unread': !notification.isRead }" (click)="navigateRespectivePage(notification)">
                    <div class="title">
                        @if (!notification.isRead) {
                        <span class="unread-dot"></span>
                        }@else {
                        <span class="read-dot"></span>
                        }
                        <h4 class="notification-header">
                            {{ notification.title }}
                        </h4>
                    </div>

                    <div class="notification-message">{{ notification.message }}</div>
                    <div class="footer">
                        <span>From</span>
                        <span class="footer-text"> <span class="fw_notify"> {{ notification.sender }} </span> <span
                                class="notify_seperator">|</span> <span class="fw_notify"> {{ notification.time |
                                timeAgo }}</span></span>
                    </div>
                </div>
                }@else {
                <h3 class=" m-4 text-center">{{staticText?.common?.no_record}}</h3>
                }

                }@else {
                <!-- Loader start ............ -->
                <div class="spinner_center">
                    <div class="overlay">
                
                        <div class="loader loader_big"></div>
                    </div>
                </div>
                <!-- ............ Loader End  -->
                }

            </div>

            @if(notifications?.length){
            <div class="footer-link" [hidden]="hideNotificationBtn" (click)="$event.stopPropagation()">
                <span class="see-all" (click)="loadMore()">
                    {{staticText?.common?.see_more_notification}}
                </span>
            </div>
            }

        </div>

    </mat-menu>

</div>