import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { PagePermssions, routePath, scp, SCREENMODE, USERTYPE } from './core/services/utils/constants';
import { UnderDevelopmentComponent } from './modules/under-development/under-development.component';
import { AuthGuard } from './core/guard/auth.guard';
import { SelfRegisterComponent } from './components/scp/self-register/self-register.component';  // Import the standalone component
import { ScpListComponent } from './components/scp/scp-list/scp-list.component';

import { ViewRegistrationComponent } from './components/scp/view-registration/view-registration.component';
import { ViewApplicationComponent } from './components/scp/view-application/view-application.component';
import { OnboardScpComponent } from './components/scp/onboard-scp/onboard-scp.component';  // Import the standalone component


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    data: {},
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'user-management',
        title: 'User Management',
        data: { id: USERTYPE.ADMIN_ID, breadcrumb: 'User Management', page_title: 'test page title', requiredPermission: [PagePermssions.VIEW_CUSTOMUSER, PagePermssions.VIEW_CUSTOMUSER, PagePermssions.VIEW_ENTITY] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'location',
        title: 'Location',
        data: { id: USERTYPE.ADMIN_ID, breadcrumb: 'Configuration', page_title: 'Configuration', },
        loadChildren: () => import('./modules/configuration/location/location.module').then(m => m.LocationModule),
      },
      {
        path: 'tax-management',
        title: 'Tax Management',
        data: { breadcrumb: 'Tax Management', page_title: 'Tax Management', },
        loadChildren: () => import('./modules/tax-management/tax-management.module').then(m => m.TaxManagementModule),
      },
      {
        path: 'order',
        title: 'Order Management',
        data: { breadcrumb: 'Order Management', page_title: 'Order Management', },
        loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
      },
      {
        path: 'grievance-management',
        title: 'Grievance Management',
        data: { breadcrumb: 'Grievance Management', page_title: 'Grievance Management', },
        loadChildren: () => import('./modules/grievance-management/grievance-management.module').then(m => m.GrievanceManagementModule),
      },
      {
        path: 'forecast',
        title: 'Forecast',
        data: { breadcrumb: 'Forecast', page_title: 'Forecast', },
        loadChildren: () => import('./modules/forecast/forecast.module').then(m => m.ForecastModule),
      },
      {
        path: 'reports',
        title: 'Reports',
        data: { breadcrumb: '', page_title: '', },
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'scp-mgmt/scp-registration',
        title: 'Scp',
        data: { mode: scp.registration, id: USERTYPE.ADMIN_ID, breadcrumb: 'Self Registration Request', page_title: 'Self Registration Request' },
        component: ScpListComponent
      },
      {
        path: 'scp-mgmt/scp-onboard',
        title: 'Onboard process',
        data: { mode: scp.onboard, id: USERTYPE.ADMIN_ID, breadcrumb: 'Onboard process Requests', page_title: 'Onboard process Requests' },
        component: ScpListComponent
      },
      {
        path: 'scp-mgmt/scp-list',
        title: 'Onboard process',
        data: { mode: scp.scp_list, id: USERTYPE.ADMIN_ID, breadcrumb: 'Onboard process Requests', page_title: 'Onboard process Requests' },
        component: ScpListComponent
      },
      {
        path: 'scp-mgmt/view-onboarding-status/:id',
        title: 'Application Status',
        data: { mode: SCREENMODE.VIEW, id: USERTYPE.ADMIN_ID, operation_type: SCREENMODE.ONBOARDING, breadcrumb: '', page_title: '' },
        component: ViewRegistrationComponent
      },
      {
        path: 'scp-mgmt/view-status/:id',
        title: 'Application Status',
        data: { mode: SCREENMODE.VIEW, id: USERTYPE.ADMIN_ID, operation_type: SCREENMODE.REGISTRATION, breadcrumb: '', page_title: '' },
        component: ViewRegistrationComponent
      },
      {
        path: 'scp-mgmt/view-onboarding-status/:id/:view-mode',
        title: 'Application Status',
        data: { mode: SCREENMODE.VIEW, id: USERTYPE.ADMIN_ID, operation_type: SCREENMODE.ONBOARDING, breadcrumb: '', page_title: '' },
        component: ViewRegistrationComponent
      },
      {
        path: 'scp-mgmt/view-status/:id/:view-mode',
        title: 'Application Status',
        data: { mode: SCREENMODE.VIEW, id: USERTYPE.ADMIN_ID, operation_type: SCREENMODE.REGISTRATION, breadcrumb: '', page_title: '' },
        component: ViewRegistrationComponent
      },
      {
        path: 'scp-mgmt/edit-registration/:id',
        title: 'Application Status',
        data: { mode: SCREENMODE.EDIT, id: USERTYPE.ADMIN_ID, operation_type: SCREENMODE.REGISTRATION, breadcrumb: '', page_title: '' },
        component: ViewRegistrationComponent
      },
      {
        path: 'scp-mgmt/application-status',
        title: 'Application Status',
        data: { id: USERTYPE.SCP_ID, breadcrumb: '', page_title: '' },
        component: ViewApplicationComponent
      },
      {
        path: 'scp-mgmt/onboard-scp/:id',
        title: 'Onboard SCP',
        data: { mode: SCREENMODE.EDIT, id: USERTYPE.ADMIN_ID, breadcrumb: '', page_title: '' },
        component: OnboardScpComponent
      },
      {
        path: 'scp-mgmt/onboard-scp/:id/:stepper',
        title: 'Onboard SCP',
        data: { mode: SCREENMODE.EDIT, id: USERTYPE.ADMIN_ID, breadcrumb: '', page_title: '' },
        component: OnboardScpComponent
      },
      {
        path: 'product',
        title: 'Product',
        data: { id: USERTYPE.ADMIN_ID, breadcrumb: 'Product', page_title: 'Product' },
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
      }
    ]
  },
  {
    path: 'under-developement',
    title: 'Statisics',
    data: { breadcrumb: 'Under Developement', page_title: 'Under Developement', requiredPermission: [PagePermssions.DAILYCASE_MENU_PERMISSION] },
    component: UnderDevelopmentComponent
  },
  {
    path: 'self-register',   // New route for the standalone component
    title: 'Self Registration Form',
    component: SelfRegisterComponent,
    canActivate: [],   // Optional: Protect this route with AuthGuard
    data: { breadcrumb: 'Self Registration Form', page_title: 'Self Registration Form', requiredPermission: [] },
    loadChildren: () => import('./core/error-pages/error-pages.module').then(m => m.ErrorPagesModule)
  },
  {
    path: '**',
    redirectTo: routePath?.VIEW_REDIRECT_404_PATH,
    pathMatch: 'full'
  },
  {
    path: `${routePath?.VIEW_PATH}`,
    data: {},
    loadChildren: () => import('./core/error-pages/error-pages.module').then(m => m.ErrorPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

