import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import {
  APPLICATION,
  ApiMethod,
  Endpoints,
  PagePermssions,
  routePath,
  SCREENMODE,
  USERGROUPS,
  FORMAT,
  ONBOARDSLUG,
  CODING_CONFIG,
  outSystemFileuploadStatus,
  UID_CODE_FILE_UPLOAD_FORMAT,
  MAX_UID_CODE_FILE_UPLOAD_SIZE,
} from 'src/app/core/services/utils/constants';
import { CustomCommentsComponent } from 'src/app/shared/components/custom-comments/custom-comments.component';
import { NextStatusActionComponent } from 'src/app/shared/components/next-status-action/next-status-action.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicFormComponent } from 'src/app/shared/components/dynamic-form/dynamic-form.component';
import { AddCommentsComponent } from 'src/app/shared/components/add-comments/add-comments.component';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import {
  FORM_SECTIONS,
  ONBOARD_BANKINFO_FORM_SECTIONS,
  ONBOARD_OTHERINFO_FORM_SECTIONS,
  ONBOARD_SITEINFO_FORM_SECTIONS,
  ONBOARD_SUPPLYINFO_FORM_SECTIONS,
  ONBOARD_USERINFO_FORM_SECTIONS,
} from '../form-config/form-config.model';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import {
  CommentConfig,
  CommentsAPIS,
  CommentsPermission,
  OredrServiceInfo,
} from 'src/app/core/services/common/common.interface';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { OnboardingService } from 'src/app/core/services/registration/onboarding.service';
import {
  FormSection,
  postalCodeField,
} from 'src/app/shared/components/dynamic-form/form-sections.model';
import _ from 'lodash';
import { TranslationService } from 'src/app/shared/services/translation.service';
import {
  formKeys,
  SCP_MANEGEMENT,
} from '../scp-table-config/table-schema-model';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import moment from 'moment';
import { catchError, of } from 'rxjs';
@Component({
  selector: 'app-view-registration',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule,
    CustomCommentsComponent,
    NextStatusActionComponent,
    DynamicFormComponent,
  ],
  templateUrl: './view-registration.component.html',
  styleUrl: './view-registration.component.scss',
})
export class ViewRegistrationComponent implements OnInit {
  screenMode: any = SCREENMODE;
  permission: any = PagePermssions;
  editId: any;
  nextStatus: any;
  tableFields: any = SCP_MANEGEMENT?.revision_history_table_fields;
  disableForm: boolean = false;
  mode: string = 'view';
  apiEndpoint: any;
  formConfig: any;
  loadMasterAPI: string = 'certificate_info';
  selectedIndex: number = 0;
  registrationData: any = null;
  locationdetails: any = [];
  staticText: any = TranslationService.staticTextData;
  appConfig: any = APPLICATION.config;
  pageMode: any;
  operationType: any;
  selectedFiles: { [key: string]: File } = {};

  @ViewChild(DynamicFormComponent) dynamicFormComponent!: DynamicFormComponent;
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;

  @ViewChild('apiTemplateRef', { static: true })
  apiTemplateRef!: TemplateRef<any>;

  bankInfoConfig: FormSection[] = [];
  supplyChainInfoConfig: FormSection[] = [];
  userInfoConfig: FormSection[] = [];
  otherInfoConfig: FormSection[] = [];
  licenseType: any = null;

  onboardData: any;
  siteInfoConfig: any = [];
  otherInfoData: any;
  bankInfoMode: any;
  supplyChainMode: any;
  userMode: any;
  otherMode: any;
  scpHeaderInfo: any = {};
  viewMode: any = null;
  @Input() customClass: string = '';

  onboard_commentsApis: CommentsAPIS = {
    get: Endpoints.ONBOARD_COMMENTS,
    post: Endpoints.ONBOARD_COMMENTS_CREATE,
  };
  commentsApis: CommentsAPIS = {
    get: Endpoints.COMMENTS,
    post: Endpoints.COMMENTS_CREATE,
  };

  onboard_commentsPermission: CommentsPermission = {
    view: PagePermssions.CAN_VIEW_ONBOARDCOMMENT,
    add: PagePermssions.CAN_ADD_ONBOARDCOMMENT,
  };
  commentsPermission: CommentsPermission = {
    view: PagePermssions.CAN_VIEW_REGISTRATIONCOMMENT,
    add: PagePermssions.CAN_ADD_REGISTRATIONCOMMENT,
  };
  versionHistory: any = [];
  totalRecords: any;
  startRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  updatedSectionKeys: any;
  orderServiceInfo: any = {};
  productDetailsList: any[] = [];
  allProductIds: any;
  manufacterId: any;
  apiDeliveryDetails: any;
  constructor(
    private _onBoard: OnboardingService,
    private _modal: ModalService,
    private _http: HttpService,
    private activated_route: ActivatedRoute,
    private _common: CommonService,
    private _storage: StorageService,
    private _loader: LoaderService,
    private _router: Router,
    private _lodash: LodashService,
    private datePipe: DatePipe,
    private _formValidator: FormValidatorService,
    protected breadcrumbService: BreadCrumbService
  ) {}

  ngOnInit(): void {
    /* FORM_SECTIONS?.forEach(element => {
       if (element.key == 'ownership_info') {
         element.meta.withBorder = true
       }
     }); */

    this._common.getAppConfig().subscribe((response: any) => {
      this.appConfig = response;
      this.bankInfoConfig = ONBOARD_BANKINFO_FORM_SECTIONS;
      this.appConfig?.bank_keys?.forEach((element: any) => {
        const alreadyExist = this.bankInfoConfig?.[0]?.fields?.some(
          (x) => x.name == element?.key_name
        );

        if (!alreadyExist) {
          this.bankInfoConfig?.[0]?.fields?.push({
            type: 'text',
            label: element?.key_value,
            name: element?.key_name,
            validators: [],
          });
        }
      });
    });

    this.breadcrumbService.breadCrumbView(false);
    // this.formConfig = FORM_SECTIONS;
    this.formConfig = this.processFormConfig();
    this.editId = this.activated_route.snapshot.paramMap.get('id');
    this.viewMode = this.activated_route.snapshot.paramMap.get('view-mode');
    if (this.checkAdminRole) {
      this.getVersionHistory(this.editId);
    }

    this.checkOnboardDataExist(this.editId);

    this.supplyChainInfoConfig = this._common.includePostalCode(
      ONBOARD_SUPPLYINFO_FORM_SECTIONS,
      true
    );
    this.userInfoConfig = ONBOARD_USERINFO_FORM_SECTIONS;
    this.otherInfoConfig = ONBOARD_OTHERINFO_FORM_SECTIONS;

    this.apiEndpoint = Endpoints.GET_SCP_REGN_DETAIL + this.editId;
    this.getRegistrationData(this.apiEndpoint);
    this.getRouteParams();
    this.getOrderServiceInfo();
  }

  private patchApidetails(data: any): void {
    this.apiDeliveryDetails = data;
  }

  private getOrderServiceInfo(): void {
    this._onBoard
      .getOrderServiceInfo()
      .subscribe((response: OredrServiceInfo) => {
        this.orderServiceInfo = response?.data ?? {};
        this._onBoard.includeOthersReqConfig(
          this.otherInfoConfig as FormSection[],
          this.orderServiceInfo,
          this.patchApidetails.bind(this),
          this.apiTemplateRef
        );
      });
  }
  get isOutSystem(): boolean {
    return this.orderServiceInfo?.generation_method == CODING_CONFIG.OUTSYSTEM;
  }

  get siteUpdated(): boolean {
    return this.updatedSectionKeys?.includes('site_details');
  }
  get userUpdated(): boolean {
    return this.updatedSectionKeys?.includes('user_info');
  }
  get supplyChainUpdated(): boolean {
    return this.updatedSectionKeys?.includes('supplier_info');
  }
  get bankUpdated(): boolean {
    return this.updatedSectionKeys?.includes('bank_info');
  }
  get otherUpdated(): boolean {
    return this.updatedSectionKeys?.includes('general_info');
  }
  get inspectionUpdated(): boolean {
    return false;
  }
  get checkAdminRole(): boolean {
    const userDetails = this._storage.getUserDetails();
    return (
      userDetails?.groups?.[0]?.name == USERGROUPS?.RA_ADMINISTRATOR ||
      userDetails?.is_superuser
    );
  }

  private latestRecKeys(latestRec: any): any {
    const listOfRec = this.versionHistory.filter(
      (x: any) => x.create_at == latestRec.create_at
    );
    const data = listOfRec.map((x: any) => x.version_changes);
    const uniqueData = [...new Set(data)];
    return uniqueData;
  }

  private getLatestRecord(transformedData: any) {
    // Find the latest modified record
    const latestRecord = transformedData.reduce((latest: any, item: any) => {
      return moment(item.create_at).isAfter(moment(latest.create_at))
        ? item
        : latest;
    }, transformedData[0]);
    return latestRecord;
  }

  private getNameFromKey(keyname: string): string {
    const _formKeys: any = formKeys;
    return _formKeys[keyname] ?? '';
  }

  private highlightChanges(str1: string, str2: string): string {
    const words1: string[] = str1.split(/\s+/); // Split str1 into words
    const words2: string[] = str2.split(/\s+/); // Split str2 into words
    const set1: Set<string> = new Set(words1); // Set of words in the first string
    const set2: Set<string> = new Set(words2); // Set of words in the second string

    const result: string[] = [];

    // Highlight removed words from str1
    for (let word of words1) {
      if (!set2.has(word)) {
        result.push(`<span class="removed-highlightTxt">${word}</span>`); // Removed word
      } else {
        result.push(`<span class="common-highlightTxt">${word}</span>`); // Common word
      }
    }

    // Highlight newly added words from str2
    for (let word of words2) {
      if (!set1.has(word)) {
        result.push(`<span class="new-highlightTxt">${word}</span>`); // Newly added word
      }
    }

    return result.join(' ');
  }

  private getAction(change: any) {
    const _change = Object.keys(change);
    if (_change.some((x) => x == 'updated_field')) return 'Updated';
    if (_change.some((x) => x == 'created')) return 'Added';
    if (_change.some((x) => x == 'deleted')) return 'Deleted';
    else return '';
  }
  private processChanges(
    changesArray: any,
    lastUpdatedOn: any,
    lastUpdatedBy: any,
    version_changes: any
  ): any {
    let result: any = [];
    changesArray.forEach((change: any) => {
      let column = this.getNameFromKey(
        change?.updated_field || change?.created || change?.deleted
      );
      let origin = change?.old_value || null;
      let after_changes = this.highlightChanges(
        change?.old_value?.toString() || '',
        change?.new_value?.toString() || ''
      );

      result.push({
        section: this.getNameFromKey(version_changes),
        action: this.getAction(change),
        version_changes: version_changes,
        column,
        origin,
        after_changes,
        track_status: 'Compare changes',
        create_at:
          this.datePipe?.transform(lastUpdatedOn, FORMAT.DATE_TIME) ?? '',
        create_at_without_time:
          this.datePipe?.transform(lastUpdatedOn, FORMAT.DATE_FORMAT) ?? '',
        author: lastUpdatedBy,
      });
    });
    return result;
  }

  private commonValidation(entry: any, result: any, category: any) {
    if (entry.version_changes) {
      entry.version_changes.forEach((version: any) => {
        result.push(
          this.processChanges(
            version.changes,
            version.last_updated_on,
            version.last_updated_by,
            category
          )
        );
      });
    } else {
      result.push(
        this.processChanges(
          entry.changes,
          entry.last_updated_on,
          entry.last_updated_by,
          category
        )
      );
    }
  }

  private mapVersionHistory(data: any): any {
    let result: any = [];
    const siteSection = [
      'site_details',
      'billing_address',
      'shipping_address',
      'product_details',
      'production_details',
    ];
    const companySection = [
      'bank_info',
      'user_info',
      'general_info',
      'supplier_info',
    ];
    data.records.forEach((record: any) => {
      if (record.site_info) {
        Object.values(record.site_info).forEach((site: any) => {
          siteSection.forEach((category: any) => {
            if (site[category]) {
              site[category].forEach((entry: any) => {
                this.commonValidation(entry, result, category);
              });
            }
          });
        });
      }

      companySection.forEach((category) => {
        if (record[category]) {
          record[category].forEach((entry: any) => {
            this.commonValidation(entry, result, category);
          });
        }
      });
    });
    result = result?.flat();
    return result.sort(
      (a: any, b: any) =>
        moment(b.create_at, 'DD/MM/YYYY, hh:mm A').valueOf() -
        moment(a.create_at, 'DD/MM/YYYY, hh:mm A').valueOf()
    );
  }

  private getVersionHistory(id: any, sorting: boolean = false) {
    this._onBoard.getVersionHistory(id as any).subscribe((response: any) => {
      this.versionHistory = response;
      let data = response.data || {};
      this.totalRecords = data?.total_records;
      this.versionHistory = this.mapVersionHistory(data);
      const latestRec = this.getLatestRecord(this.versionHistory);
      this.updatedSectionKeys = this.latestRecKeys(latestRec);
      this.cTable.setTableTotalRecordsCnt(this.versionHistory?.length);
      this.cTable.refreshTableData(this.versionHistory, sorting, this.startRow);
    });
  }

  processFormConfig() {
    let formConfig: any = [];
    formConfig = _.cloneDeep(FORM_SECTIONS);

    for (let i = formConfig.length - 1; i >= 0; i--) {
      const section = formConfig[i];

      if (section.key === 'business_info') {
        const isPostalCodeRequired =
          JSON.parse(localStorage.getItem('appConfig') || '{}')
            ?.geographical_classification?.is_postal_code_required || false;

        section.fields.forEach((field: any, index: number) => {
          if (field.name === 'license_type' && field?.meta?.onchangeCallback) {
            field.meta.onchangeCallback =
              this.loadLicenseInfoFormConfig.bind(this);
          } else if (field.type === 'location_field' && isPostalCodeRequired) {
            section.fields.splice(index + 1, 0, postalCodeField);
          }
        });
      }
    }

    return formConfig;
  }

  loadLicenseInfoFormConfig(data: any, form?: any) {
    if (!data?.id) return;
    this._loader.show();
    const endpoint: any =
      Endpoints.GET_REGISTRATION_CERTIFICATES + `?licensetype=` + data?.id;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this._loader.hide();
        const apidata: any = {};
        let certificateFieldConfig: any = response?.data || [];

        // Merge data into schema
        const mergedSchema = certificateFieldConfig.map((item: any) => {
          // Find matching data entry
          const matchingData = this.registrationData?.certificate_info.find(
            (d: any) => d.certificate === item.id
          );

          item.certificate = item.id;
          item.resetAttachments = true;

          // If matching data is found, merge it into the schema object
          if (matchingData) {
            return {
              ...item,
              ...matchingData, // Copy all fields from matching data
              attachments: matchingData.attachments || [], // Ensure attachments are included
            };
          } else {
            item.id = null;
            item.certificate_number = '';
            item.expiry_date = '';
            item.attachments = null;
          }

          // Return the original schema item if no match is found
          return item;
        });

        apidata[this.loadMasterAPI] = mergedSchema;
        this.reloadLicenceInfoSection(apidata);
      });
  }

  reloadLicenceInfoSection(apidata: any) {
    const section = this.formConfig.filter(
      (option: any) => option.key === this.loadMasterAPI
    );

    setTimeout(() => {
      this.dynamicFormComponent?.loadFormForMultipleSection(
        section[0],
        apidata,
        this.loadMasterAPI,
        true
      );
    }, 300);
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    this.operationType = routeInfo?.value?.operation_type;
  }

  edit() {
    this._router.navigate(['scp-mgmt/edit-registration/' + this.editId]);
  }

  navigateToHistory() {
    if (this.operationType == this.screenMode?.REGISTRATION) {
      this._router.navigate(['iam/history/scp_registration/' + this.editId]);
    } else {
      this._router.navigate(['iam/history/scp_onboard/' + this.editId]);
    }
  }

  view() {
    if (this.operationType == this.screenMode?.REGISTRATION) {
      this._router.navigate(['scp-mgmt/view-status/' + this.editId]);
    } else if (this.operationType == this.screenMode?.ONBOARDING) {
      this._router.navigate(['scp-mgmt/view-onboarding-status/' + this.editId]);
    }
  }

  editOnboarding(index: any) {
    if (index)
      this._router.navigate([
        'scp-mgmt/onboard-scp/' + this.editId + '/' + index,
      ]);
    else this._router.navigate(['scp-mgmt/onboard-scp/' + this.editId]);
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log(MatTabChangeEvent);
  }

  expandSiteDetails(item: any, items: any) {
    item.expand = !item.expand;
  }

  /**
   * @description
   * Filter array keyname id's............................
   * @param data
   * @returns
   */
  transformData(data: any, keyName: string) {
    return data?.map((item: any) => {
      let transformed: any = {
        id: item.id,
        key: keyName,
        display_name: item.display_name,
      };
      if (item.child_keys.length > 0) {
        transformed.child = [];
        item.child_keys.forEach((key: string) => {
          transformed.child = transformed.child.concat(
            this.transformData(item[key], key)
          );
        });
      }

      return transformed;
    });
  }

  //............................Filter array keyname id's

  /**
   * @description
   * Check the all onboarding section complete or not
   * @returns respective boolean
   */

  get hasOnboardfullfill(): boolean {
    return (
      !!this._onBoard.onboardingInfo.site_info?.length &&
      this._onBoard.onboardingInfo?.supplier_info?.length &&
      this._onBoard.onboardingInfo.user_info?.length &&
      this._onBoard.onboardingInfo?.general_info?.id &&
      this._onBoard.onboardingInfo?.bank_info?.id &&
      this.scpHeaderInfo?.onboard_exists
    );
  }

  /**
   * @description
   * Before complete enable edit
   * @returns respective boolean
   */

  get hasEditOnboarding(): boolean {
    return !this.scpHeaderInfo?.onboard_complete;
  }

  get viewLocationDetails(): any {
    let groupDetails = [];
    for (let element of Object.keys(this.locationdetails)) {
      let obj: any = {};
      obj.display_name = element;
      obj.group = this.locationdetails[element];
      obj.concatName = this.locationdetails[element]
        ?.map((x: any) => x.display_name)
        ?.toString();
      groupDetails.push(obj);
    }
    return groupDetails;
  }

  /**
   * @description
   * Nested JSON built as flattern JSON................................
   * @param jsonData
   * @param deleteMode
   * @returns
   */

  flattenJson(jsonData: any, deleteMode: boolean) {
    let result: any = [];

    function flatten(item: any) {
      result.push(item);
      if (item.child) {
        item.child.forEach(flatten);
        if (deleteMode) {
          delete item.child;
        }
      }
    }
    jsonData?.forEach(flatten);
    return result;
  }

  getOnboardSiteFormConfig(licenseType: any) {
    let config: any;
    let intialConfig = _.cloneDeep(ONBOARD_SITEINFO_FORM_SECTIONS);
    if (licenseType?.name?.toLowerCase() !== 'manufacturers') {
      const clonerFormConfig = intialConfig.map((x) => x);
      const formConfig = this._common.removeSectionByKey(
        clonerFormConfig,
        'production_details'
      );
      config = formConfig.map((x) => x);
    } else {
      config = intialConfig.map((x) => x);
    }

    config = this._common.includePostalCode(config);
    return config;
  }

  getOnboard(id: any) {
    this._onBoard.getOnboard(id).subscribe((response: any) => {
      this._onBoard.onboardingInfo = response?.data;
      this.onboardData = response?.data ?? {};
      this.getOnboardNextStatus();
      this._onBoard.onboardingInfo.site_info.forEach(
        (element: any, index: any) => {
          this.siteInfoConfig.push({
            name: element?.site_details?.name,
            mode: 'view',
            valid: true,
            expand: index == 0,
            data: element,
            section: this.getOnboardSiteFormConfig(this.licenseType),
          });
        }
      );
      this.getAvailableCodeCount(response?.data?.general_info?.company);
      this.otherInfoData = {
        ...this.onboardData?.general_info,
        ...(this.onboardData?.general_info.scp_delivery_details ?? {}),
      };
      this.bankInfoMode = this.onboardData?.bank_info?.id ? 'edit' : 'add';
      this.supplyChainMode = this.onboardData?.supplier_info?.length
        ? 'edit'
        : 'add';
      this.userMode = this.onboardData.user_info?.length ? 'edit' : 'add';
      this.otherMode = this.onboardData?.general_info?.id ? 'edit' : 'add';
    });
  }

  private getOnboardNextStatus(): void {
    if (this.getOnboardApproveStatus) return;
    if (
      this._common.gotPermission([this.permission.VIEW_ONBOARD], true) &&
      this.scpHeaderInfo?.onboard_complete
    ) {
      this.getOnboardingNextStatus(this.editId);
    }
  }

  checkOnboardDataExist(id: any) {
    this._onBoard
      .getSCPRegistrationHeaderInfo(id)
      .subscribe((response: any) => {
        const data = response?.data || {};
        this.licenseType = data.license_type ?? {};
        this.scpHeaderInfo = data;
        if (
          this.scpHeaderInfo?.onboard_exists &&
          this.operationType == this.screenMode?.ONBOARDING
        ) {
          this.getOnboard(this.editId);
        } else {
          this.getNextStatus(this.editId);
        }
      });
  }

  private getAvailableCodeCount(id: any): void {
    this._onBoard.getAvailableCodeCount(id).subscribe((response: any) => {
      this.updateAvailableCounts(response?.data);
    });
  }

  private updateAvailableCounts(data: any): void {
    this.siteInfoConfig?.forEach((site: any) => {
      site?.data?.product_details?.forEach((product: any) => {
        product.available_count =
          data?.find((x: any) => x?.product_id == product?.product)
            ?.available_count ?? 0;
      });
    });
  }

  private updateAvailableCountOnlyMatch(data: any): void {
    this.siteInfoConfig?.forEach((site: any) => {
      site?.data?.product_details?.forEach((product: any) => {
        const matchingProduct = data[product?.product]; // Access by product_id key
        if (matchingProduct) {
          product.available_count = matchingProduct.available_count;
        }
      });
    });
  }

  getRegistrationData(endpoint: any) {
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.registrationData = response?.data;
        if (this.pageMode == this.screenMode?.VIEW) {
          let locationDetails: any = [];
          this.registrationData?.business_info?.location?.child_keys?.forEach(
            (key: any) => {
              locationDetails = this.transformData(
                this.registrationData?.business_info?.location[key],
                key
              );
            }
          );

          this.registrationData.certificate_info =
            this.registrationData?.certificate_info?.filter(
              (item: any) => item.certificate_number != null
            );

          const locationdetails = this.flattenJson(locationDetails, true);
          this.locationdetails = this._lodash.groupBy(locationdetails, 'key');
          this.reloadLicenceInfoSection(this.registrationData);
        } else if (this.pageMode == this.screenMode?.EDIT) {
          const licenseParam = {
            id: this.registrationData?.business_info?.license_type?.id,
          };
          this.loadLicenseInfoFormConfig(licenseParam);
        }
      });
  }

  getNextStatus(id: any) {
    let endpoint: any = Endpoints.GET_NEXT_STATUS + id;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.nextStatus = response?.data;
      });
  }

  getOnboardingNextStatus(id: any) {
    let endpoint: any = Endpoints.GET_ONBOARDING_NEXT_STATUS + id;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.nextStatus = response?.data;
      });
  }

  nextActionEmit(action: any) {
    this.getComments(action);
  }

  getComments(action: any) {
    const nextStatus = this._lodash.find(
      this.nextStatus.next_states,
      'slug',
      action
    );

    const config: CommentConfig = {
      id: this.editId,
      enableComments: true,
      enableAttachment: true,
      message: true,

      messagetxt: {
        message: nextStatus.message,
      },

      header: {
        title: `${nextStatus.label} Request - <span class="id_txt">${this.registrationData?.validation_info?.registration_code}</span>`,
      },
      footer: {
        cancelBtnTxt: 'Cancel',
        submitBtnTxt: nextStatus.label,
        submitBtnStyle: {
          'background-color': nextStatus.color_code || '#0061f7',
          color: nextStatus.text_color_code || '#ffffff',
        },
      },
    };
    this._modal
      .openCommonDialog({
        component: AddCommentsComponent,
        data: config,
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          if (this.scpHeaderInfo?.onboard_complete)
            this.updateOnboardStatus(result, action);
          else this.updateStatus(result, action);
        }
      });
  }

  close() {
    const userDetails: any = this._storage.getUserDetails();
    if (
      userDetails?.groups?.[0]?.name ==
      USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR
    )
      this._router.navigate([`${routePath?.SCP_APPLICATION_PATH}`]);
    else {
      if (this.viewMode == '3') this._router.navigate([routePath.SCP_LIST]);
      else if (
        this.operationType == this.screenMode?.REGISTRATION ||
        this.operationType == this.screenMode?.ONBOARDING
      )
        this._router.navigate([routePath.SCP_PATH]);
    }
  }

  private navigateValidation() {
    const userDetails: any = this._storage.getUserDetails();
    if (
      userDetails?.groups?.[0]?.name ==
      USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR
    )
      this._router.navigate([`${routePath?.SCP_APPLICATION_PATH}`]);
    else this._router.navigate([routePath.SCP_PATH]);
  }

  updateStatus(data: any, action: string) {
    let formData = new FormData();
    const payload = {
      comment: data?.message,
    };
    formData.append('json_data', JSON.stringify(payload));
    if (data.attachements) formData.append('attachments', data.attachements);
    let endpoint: any = `${Endpoints.UPDATE_SCP_STATUS}${data?.id}/?status_key=${action}`;
    this._loader.show();
    this._http
      .requestCall(endpoint, ApiMethod.PUT, formData, {
        observe: 'response',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .subscribe((response: any) => {
        this._loader.hide();

        this._modal
          .openSucceedDialog({
            data: {
              header: 'Success!',
              msg: response?.body?.data?.message,
              code: response?.body?.data?.code,
              paragraph: response?.body?.data?.message,
            },
          })

          .afterClosed()
          .subscribe((result: any) => {
            if (result) {
              this.navigateValidation();
              return;
            }
          });
      });
  }

  updateOnboardStatus(data: any, action: string) {
    let formData = new FormData();
    const payload = {
      comment: data?.message,
    };
    formData.append('json_data', JSON.stringify(payload));
    if (data.attachements) formData.append('attachments', data.attachements);
    let endpoint: any = `${Endpoints.UPDATE_ONBOARD_STATUS}${data?.id}/?status_key=${action}`;
    this._loader.show();
    this._http
      .requestCall(endpoint, ApiMethod.PUT, formData, {
        observe: 'response',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .subscribe((response: any) => {
        this._loader.hide();
        this._modal
          .openSucceedDialog({
            data: {
              header: 'Success!',
              msg: `${response?.body?.data?.message}`,
              code: response?.body?.data?.code,
              paragraph: response?.body?.data?.message,
            },
          })
          .afterClosed()
          .subscribe((result: any) => {
            if (result) {
              this.navigateValidation();
              return;
            }
          });
      });
  }

  triggerValidation() {
    if (this.dynamicFormComponent) {
      this.dynamicFormComponent.validateForm();

      if (this.dynamicFormComponent?.isFirstGroupEmpty('ownership_info')) {
        this._formValidator.validOwnershipData();
      }
    } else {
      console.error('DynamicFormComponent is not available');
    }
  }

  handleFormSubmit(formData: any) {
    if (
      this.dynamicFormComponent?.form?.valid &&
      this._formValidator.validateOwnershipPercentage(formData)
    ) {
      // formData.business_info.location= 1;
      const formDataObject = new FormData();
      this.selectedFiles = {};

      // Transform the certificate_info
      const transformedCertificateInfo = formData.certificate_info
        .filter((info: any) => info.certificate_number) // Filter out entries without certificate_number
        .map((info: any) => {
          // Ensure info.attachments is an array
          const attachments = Array.isArray(info.attachments)
            ? info.attachments
            : [];

          // Map attachments to unique names and store in selectedFiles
          const uniqueAttachments = attachments.length
            ? attachments.map((file: any) => {
                if (!file || !file?.name || file.id) {
                  return '';
                }

                const uniqueName = this._common.generateUniqueFileName(file);
                this.selectedFiles[uniqueName] = file; // Store file with unique name
                return uniqueName || '';
              })
            : [];
          // Remove empty strings or null values
          const cleanedAttachments = uniqueAttachments.filter(
            (attachment: any) => attachment && attachment.trim() !== ''
          );

          return {
            id: info.id,
            certificate: info.certificate,
            certificate_number: info.certificate_number,
            expiry_date: info.expiry_date,
            attachments: cleanedAttachments,
            is_not_applicable: info.is_not_applicable,
          };
        });

      formData['business_info']['old_email'] =
        this.registrationData?.business_info?.email;
      // Include the entire form data with the transformed certificate info

      formData = this._common.removeDefaultSuffix(formData);

      const completeFormData = {
        ...formData,
        certificate_info: transformedCertificateInfo,
        id: this.editId,
      };

      // Append the transformed certificate info as JSON
      formDataObject.append('json_data', JSON.stringify(completeFormData));

      // Append files directly
      if (this._common.isNotEmptyObject(this.selectedFiles)) {
        Object.keys(this.selectedFiles).forEach((uniqueName) => {
          const file = this.selectedFiles[uniqueName];
          formDataObject.append(uniqueName, file, file.name);
        });
      }

      // Proceed with the form submission
      this.submitFormData(formDataObject);
    } else {
      // Handle form validation errors
      console.error('Form is invalid');
    }
  }

  private displaySuccessDialog(header: any, msg: any, template: any) {
    if (msg) {
      this._modal
        .openSucceedDialog({
          data: {
            header: header,
            msg: msg,
            template: template,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.close();
        });
    }
  }

  successSubmitTemplate(businessInfo: any, regnID: any): any {
    return (
      `<div class="regn-success-panel">
          <div class="row m-2">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-14"><span>Licence Type</span></label>
            <span class="color-darkgrey fw-600 d_flex">` +
      businessInfo?.license_type?.display_name +
      `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-14"><span>Last Updated Date</span></label>
            <span class="color-darkgrey fw-600 d_flex">` +
      this.datePipe.transform(new Date(), 'dd MMM yyyy') +
      `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-14"><span>Request ID</span></label>
            <span class="color-darkgrey fw-600 d_flex">` +
      regnID +
      `</span>
          </div>
          </div> 
          </div>`
    );
  }

  // Separate method to submit the form data to the API
  submitFormData(formDataObject: FormData) {
    this._loader.show();
    let endpoint: any = Endpoints.CREATE_REGISTERATION_API + this.editId + '/';
    this._http
      .requestCall(endpoint, ApiMethod.PUT, formDataObject, {
        observe: 'response',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .subscribe(
        (response: any) => {
          this._loader.hide();
          if (response.ok || response.status == 201) {
            let data = response?.body?.data || {};
            let businessInfo = data?.business_info || {};
            const regnID = data?.validation_info?.registration_code || '';
            let msg =
              this.staticText?.scp?.register?.view_edit
                ?.regn_update_success_msg || response.message;
            let bdyContent = this.successSubmitTemplate(businessInfo, regnID);
            this._modal
              .openSucceedDialog({
                data: {
                  header: 'Changes has been Saved!',
                  msg: `<p class="">` + msg + `</p>`,
                  template: bdyContent,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  // Handle successful form submission
                  this.view();
                }
              });
          } else {
            this._modal
              .openWarningDialog({
                data: {
                  paragraph:
                    `<p class="fs-14">` +
                    'Error during form submission. Please check the details and try again.' +
                    `</p>`,
                },
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  // Handle form submission error
                }
              });
          }
        },
        (error: any) => {
          this._loader.hide();
          console.error('Error during form submission:', error);
        }
      );
  }

  private saveOnboardDetailsTemplate(response: any): any {
    return (
      `<div class="regn-success-panel">
          <div class="row m-2">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Licence Type</span></label>
            <span class="color-darkgrey fw-600">` +
      response?.body?.data?.license_type +
      `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Last Updated Date</span></label>
            <span class="color-darkgrey fw-600 d_flex">` +
      this.datePipe.transform(new Date(), 'dd MMM yyyy') +
      `</span>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <label for="" class="form-label color-primary fs-12"><span>Request ID</span></label>
            <span class="color-darkgrey fw-600">` +
      response?.body?.data?.code +
      `</span>
          </div>
          </div> 
          </div>`
    );
  }

  /**
   *
   * @param id
   * @param formData
   */
  saveOnboardDetails(id: any) {
    this._loader.show();

    const payload = { is_complete: true };
    const formData = new FormData();
    formData.append('json_data', JSON.stringify(payload));

    this._onBoard.updateDetails(id, formData).subscribe((response: any) => {
      this._loader.hide();
      let msg = this._lodash.getData(response, 'body.message', false);
      if (msg) {
        let bdyContent = this.saveOnboardDetailsTemplate(response);
        this._modal
          .openSucceedDialog({
            data: {
              header: 'Onboarding Process Completed!',
              msg: `<p class="fs-14">` + msg + `</p>`,
              template: bdyContent,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              // Handle successful form submission
              this.close();
            }
          });
      }
    });
  }

  get getOnboardApproveStatus(): boolean {
    return (
      this.onboardData?.status_info?.status?.slug == ONBOARDSLUG.ONBOARD_APPROVE
    );
  }

  getBulkUploadStatusMessage(response: any): string {
    const {
      status,
      total_count,
      progress_count,
      successful_count,
      error_count,
    } = response;

    switch (status) {
      case outSystemFileuploadStatus.PROCESSING:
        return `${this.staticText?.common?.bulk_upload?.progress_content} ${progress_count}/${total_count}.`;

      default:
        return `Your previous bulk upload has failed. Please check the details and try re-uploading.`;
    }
  }

  updateAvailableUIDCount(data: any, apiData: any) {
    data.available_count = apiData?.available_count ?? 0;
    data.product_id = data?.product;
    this.updateAvailableCountOnlyMatch(data);
  }

  initiateBulkUpload(data: any) {
    this._loader.show();
    let endpoint: any =
      Endpoints.BULK_UPLOAD_STATUS_TRACK +
      '?manufacturer_id=' +
      this.registrationData?.validation_info?.id +
      '&product_id=' +
      data?.product +
      '&trigger_id=' +
      data?.id;

    this._http.requestCall(endpoint, ApiMethod.GET).subscribe(
      (response: any) => {
        this._loader.hide();
        const apiData = response?.data || {};
        data.uploadProgress = false;
        data.uploadResult = apiData;
        data.hasSuccess = false;

        if (apiData?.status == outSystemFileuploadStatus.COMPLETED) {
          data.allowUpload = true;
          data.showUploadResult = true;
          if (apiData?.is_successful) {
            data.progressContent =
              this.staticText?.common?.bulk_upload?.success_progress_content;
            data.showUploadResult = true;
            data.hasSuccess = true;
          }

          this.updateAvailableUIDCount(data, apiData);
        } else if (apiData?.status == outSystemFileuploadStatus.PROCESSING) {
          data.progressContent = this.getBulkUploadStatusMessage(apiData);
          data.allowUpload = false;
          data.uploadProgress = true;
          this.updateAvailableUIDCount(data, apiData);
        } else if (apiData?.status == outSystemFileuploadStatus.NO_RECORDS) {
          data.allowUpload = true;
        } else {
          data.progressContent = this.getBulkUploadStatusMessage(apiData);
          data.uploadProgress = true;
          data.allowUpload = true;
          data.hasFailedProgress = true;
        }

        this.bulkUpload(data);
      },
      (error: any) => {
        this._loader.hide();
        console.log('Error during form submission:', error);
      }
    );
  }

  bulkUpload(data: any) {
    this.manufacterId = this.registrationData?.validation_info?.id;
    {
      const config: any = {
        data: {
          config: {
            enable: true,
            get: Endpoints.GET_CODING_BULK_UPLOAD,
            fileName:
              this.staticText?.scp?.register?.bulk_upload?.bulk_upload_fileName,
            post: Endpoints.GET_CODING_BULK_UPLOAD,
            label:
              this.staticText?.scp?.register?.bulk_upload?.bulk_upload_label,
            upload_template:
              this.staticText?.scp?.register?.bulk_upload?.bulk_upload_template,
            bulkAction: true,
            bulk_upload_toaster:
              this.staticText?.scp?.register?.bulk_upload?.bulk_upload_toaster,
            queryparam: '?file_type=csv',
            payload: {
              manufacturer_id: this.manufacterId,
              product_id: data?.product,
              trigger_id: data?.id,
              validate: 'False',
            },
            payload_data: true,
            extension: UID_CODE_FILE_UPLOAD_FORMAT,
            maxFileSize: MAX_UID_CODE_FILE_UPLOAD_SIZE,
            allowUpload: data?.allowUpload ?? false,
            uploadProgress: data?.uploadProgress ?? false,
            progressContent: data?.progressContent ?? '',
            showUploadResult: data?.showUploadResult ?? false,
            uploadResult: data.uploadResult ?? '',
            backgroundUpload: true,
            hasFailedProgress: data.hasFailedProgress ?? false,
            hasSuccess: data.hasSuccess ?? false,
          },
        },
      };
      this._modal
        .openBulkDialog(config)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getAvailableCodeCount(
              this.registrationData?.validation_info?.id
            );
          }
        });
    }
  }
}
