import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, Subscriber, Subscription, takeUntil } from 'rxjs';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import {
  ApiMethod,
  Endpoints,
  routePath,
} from 'src/app/core/services/utils/constants';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  sideBarMode: any;
  valuationDocument: any;
  public destory = new Subject<void>();
  desktopViewWidth: number = 1100;
  showAction: boolean = false;
  isViewInSmaller: boolean = false;
  subscription!: Subscription;
  subscriptionNavigation!: Subscription;
  constructor(
    private location: Location,
    public _breadCrumb: BreadCrumbService,
    private _loader: LoaderService,
    private _CustomTableService: CustomTableService,
    private cdr: ChangeDetectorRef,
    private _storage: StorageService,
    private _http: HttpService,
    private _common: CommonService,
    private _router: Router,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.refreshUserDetails();
    this.onResize(window.innerWidth);
    this.subscription = this._breadCrumb.showAction.subscribe((data) => {
      this.showAction = data;
      this.cdr.detectChanges();
    });

    this.subscriptionNavigation = this._breadCrumb.showNavigation.subscribe(
      () => {
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionNavigation.unsubscribe();
  }

  refreshUserDetails() {
    this._common.enableMenus.next(false);
    const refreshToken = this._storage.getToken();
    if (refreshToken) {
      this._http
        .requestCall(Endpoints.TOKEN_VERIFY_AUTH, ApiMethod.POST)
        .subscribe((resp: any) => {
          this._storage.setUserDetails(JSON.stringify(resp?.data));
          if (!resp?.data?.is_superuser) {
            let userPermission = resp?.data?.groups[0]?.permissions || [];
            let permissionCheck: any = [];
            userPermission.map((item: any) => {
              permissionCheck.push(item?.codename);
            });
            this._storage.setUserPermissions(JSON.stringify(permissionCheck));
            if (resp?.data?.is_temporary_password) {
              this._router.navigate([
                `${routePath?.CHANGE_PASSWORD_REDIRECT_PATH}`,
              ]);
            }
          }

          this._common.fetchPermssions();
          this._common.getOnboardingStatus().subscribe((loginResponse: any) => {
            this._loader.hide();
            this._auth.hideMenusDepandOnOnboard(loginResponse?.status);
            this._common.enableMenus.next(loginResponse?.status);
            this._common.getProductMasterdata();
          });
        });
    }
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isViewInSmaller = width <= this.desktopViewWidth ? true : false;
  }

  uploadDetails(event: any) {
    this._loader.show();
    let endpoint: any = this._breadCrumb?.uploadAction?.api;
    let formData = new FormData();
    formData.append(
      this._breadCrumb?.uploadAction?.payloadKey ?? '',
      event?.target?.files?.[0]
    );
    event.target.value = '';
  }

  // Method to trigger back navigation
  goBack() {
    this.location.back();
  }
}
