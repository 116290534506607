<!-- <div [ngSwitch]="menuMode">
    <section class="sidenav">
        <div class="sidenav-container">
            <div class="d-flex flex-column">
                <div class="application-logo">
                    <img src="/assets/images/common/login/logo.png" class="img-fluid">
                </div>
                <div class="menu-list">
                    <div class="cursor-pointer text-capitalize" *ngFor="let menu of menuList;index as menuIndex">
                        <div class="inner-menu-box"
                            [ngStyle]="{'grid-template-columns': menu?.children?.length > 0 ? (15 + '%' + 70 + '%' + 15 + '%') : (15 + '%' + 85 + '%')}"
                            (click)="toggleMenu(true,menuIndex,null, menu, null)"
                            [ngClass]="{'active-state':menu.Activate}" [routerLink]="menu?.router">
                            <div class="p-1 left-icon">
                                <img [src]="menu.Activate ? '../../../assets/images/layout/sidenav/icons/'+menu?.icon+'_active.svg':'../../../assets/images/layout/sidenav/icons/'+ menu?.icon +'.svg'"
                                    [alt]="menu?.icon">
                            </div>
                            <div class="p-1 menu-name">
                                <p class="mb-0 fs-14">{{menu.name}}</p>
                            </div>
                            <div class="p-1 right-icon" *ngIf="menu?.children?.length > 0">
                                <img *ngIf="!menu.Activate" src="../../../assets/images/layout/sidenav/icons/arrow_down.svg">
                                <img *ngIf="menu.Activate"
                                    src="../../../assets/images/layout/sidenav/icons/arrow_down_active.svg">
                            </div>
                        </div>
                        <ng-container *ngIf="menu.expand" class="px-4">
                            <ng-container *ngFor="let submenu of menu?.children;index as subMenuIndex">
                                <div class="inner-submenu-box"
                                    (click)="toggleMenu(false,menuIndex,subMenuIndex, menu, submenu)"
                                    [routerLink]="submenu?.router"
                                    [ngClass]="{'active-submenu-state':submenu.Activate}">
                                    <img *ngIf="submenu.Activate"
                                        src="../../../assets/images/layout/sidenav/icons/ellipse_active.svg">
                                    <img *ngIf="!submenu.Activate"
                                        src="../../../assets/images/layout/sidenav/icons/ellipse.svg">
                                    <div class="p-1 sub-menu-name">
                                        <p class="mb-0 fs-14">{{submenu.name}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->


<!-- Side navigation bar -->

<div>
    <section class="sidenav">
        <div class="sidenav-container">
            <div class="d-flex flex-column">
                <div class="application-logo">
                    <img src="/assets/images/common/login/logo.png" class="img-fluid">
                    <img alt="" class="largermenu-toggle" *ngIf="showMenuToggle" (click)="sideNavToggle();showCollapseState =!showCollapseState" [src]="showCollapseState ?'assets/images/layout/header/sidenav_open.svg':'assets/images/layout/header/sidenav_close.svg'"
                            [alt]="showCollapseState ? 'toggleSideNav_open':'toggleSideNav_close'">
                </div>
                <div class="menu-list">
                    <ng-container *ngIf="menuList?.length">
                        <ul>
                            <li *ngFor="let menu of menuList;index as menuIndex">
                                <ng-container
                                    *ngTemplateOutlet="submenus; context: { $implicit:menu, index:menuIndex, position:1}">
                                </ng-container>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #submenus let-menu let-idx="index" let-position="position">
    <ng-container *ngxPermissionsOnly="menu?.permission">
        <div class="cursor-pointer text-capitalize">
            <div class="inner-menu-box" (click)="menuClick($event, menu, position)"
                [ngClass]="{'active-state':menu.activate && position == 1}" [routerLink]="menu?.router">
                <ng-container *ngIf="menu?.icon">
                    <div class="p-1 left-icon">
                        <img [src]="menu.activate ? '../../../assets/images/layout/sidenav/icons/'+menu?.icon+'_active.svg':'../../../assets/images/layout/sidenav/icons/'+ menu?.icon +'.svg'"
                            [alt]="menu?.icon">
                    </div>
                </ng-container>
                <ng-container *ngIf="!menu?.icon">
                    <div class="p-1 left-icon">
                        <div class="circle">
                            <ng-container *ngIf="!menu.activate">
                                <img src="../../../assets/images/layout/sidenav/icons/dot.svg" alt="">
                            </ng-container>
                            <ng-container *ngIf="menu.activate">
                                <img src="../../../assets/images/layout/sidenav/icons/dot_activate.svg" alt="">
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="p-1 menu-name">
                    <a href="javascript:void(0);" [routerLink]="menu?.router" class="text-decoration-none mb-0 fs-14 ">{{menu.name}}</a>
                </div>
                <ng-container *ngIf="menu?.children?.length > 0">
                    <div class="p-1 right-icon">
                        <ng-container *ngIf="!menu.expand">
                            <img src="../../../assets/images/layout/sidenav/icons/arrow_down.svg" alt="">
                        </ng-container>
                        <ng-container *ngIf="menu.expand">
                            <img src="../../../assets/images/layout/sidenav/icons/arrow_down_active.svg" alt="">
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="menu?.children?.length > 0">
            <ng-container *ngIf="menu.expand">
                <ul>
                    <li *ngFor="let submenu of menu?.children;index as subParentIndex">
                        <ng-container
                            *ngTemplateOutlet="submenus; context: { $implicit:submenu, index:subParentIndex, position:(position+1)}">
                        </ng-container>
                    </li>
                </ul>
            </ng-container>

        </ng-container>
    </ng-container>
</ng-template>