import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import {
  APPLICATION,
  PagePermssions,
  routePath,
  scp,
  SCREENMODE,
  SYSTEMCONFIG,
  USERTYPE,
} from './core/services/utils/constants';
import { UnderDevelopmentComponent } from './modules/under-development/under-development.component';
import { AuthGuard } from './core/guard/auth.guard';
import { SelfRegisterComponent } from './components/scp/self-register/self-register.component'; // Import the standalone component
import { ScpListComponent } from './components/scp/scp-list/scp-list.component';

import { ViewRegistrationComponent } from './components/scp/view-registration/view-registration.component';
import { ViewApplicationComponent } from './components/scp/view-application/view-application.component';
import { OnboardScpComponent } from './components/scp/onboard-scp/onboard-scp.component'; // Import the standalone component
import { CommonService } from './core/services/common/common.service';
import { SetupConfigurationComponent } from './modules/initial-setup/setup-configuration/setup-configuration.component';
import { TranslationService } from './shared/services/translation.service';
const staticText: any = TranslationService.staticTextData;
const systemConfig: any = SYSTEMCONFIG;

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    data: {},
    resolve: {
      resolvedData: CommonService,
    },
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'configuration/organization-setup',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.setup_configuration?.setup_configuration_title,
        data: {
          mode: SCREENMODE.EDIT,
        },
        component: SetupConfigurationComponent,
      },
      {
        path: 'configuration/organization-setup/view',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.setup_configuration?.setup_configuration_title,
        data: {
          mode: SCREENMODE.VIEW,
        },
        component: SetupConfigurationComponent,
      },
      {
        path: 'iam/user-management',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.user_management?.user?.list?.list_card_title,
        data: {
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'User Management',
          page_title: 'test page title',
          requiredPermission: [
            PagePermssions.VIEW_CUSTOMUSER,
            PagePermssions.VIEW_CUSTOMUSER,
            PagePermssions.VIEW_ENTITY,
          ],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'configuration/location',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.configuration?.location?.location?.list?.breadcrumbs,
        data: {
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'Configuration',
          page_title: 'Configuration',
        },
        loadChildren: () =>
          import('./modules/configuration/location/location.module').then(
            (m) => m.LocationModule
          ),
      },
      {
        path: 'configuration/tax-management',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.tax_management?.page_title,
        data: { breadcrumb: 'Tax Management', page_title: 'Tax Management' },
        loadChildren: () =>
          import('./modules/tax-management/tax-management.module').then(
            (m) => m.TaxManagementModule
          ),
      },
      {
        path: 'configuration/forecast-setup',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.forecast_management?.setup?.forecast_configuration,
        data: {
          breadcrumb:
            staticText?.forecast_management?.setup?.forecast_configuration ??
            'Forecast Configuration',
          page_title:
            staticText?.forecast_management?.setup?.forecast_configuration ??
            'Forecast Configuration',
        },
        loadChildren: () =>
          import(
            './modules/configuration/forecast-setup/forecast-setup.module'
          ).then((m) => m.ForecastSetupModule),
      },
      {
        path: 'configuration/secure-code-setup',
        title:
          systemConfig?.page_title +
          ' | ' +
          (staticText?.vdg_management?.title ?? 'Secure Code Configuration'),
        data: {
          breadcrumb:
            staticText?.vdg_management?.title ?? 'Secure Code Configuration',
          page_title:
            staticText?.vdg_management?.title ?? 'Secure Code Configuration',
        },
        loadChildren: () =>
          import('./modules/configuration/secure-code/secure-code.module').then(
            (m) => m.SecureCodeModule
          ),
      },
      {
        path: 'configuration/inspection-setup',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.forecast_management?.setup?.forecast_configuration,
        data: {
          breadcrumb:
            staticText?.forecast_management?.setup?.forecast_configuration ??
            'Forecast Configuration',
          page_title:
            staticText?.forecast_management?.setup?.forecast_configuration ??
            'Forecast Configuration',
        },
        loadChildren: () =>
          import(
            './modules/configuration/inspection-config/inspection-config.module'
          ).then((m) => m.InspectionConfigModule),
      },
      {
        path: 'dts-operation/order',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.order_management?.page_title,
        data: {
          breadcrumb: 'Order Management',
          page_title: 'Order Management',
        },
        loadChildren: () =>
          import('./modules/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'dts-operation/coding',
        title:
          systemConfig?.page_title + ' | ' + staticText?.coding?.page_title,
        data: {
          breadcrumb: staticText?.coding?.page_title,
          page_title: staticText?.coding?.page_title,
        },
        loadChildren: () =>
          import('./modules/coding/coding.module').then((m) => m.CodingModule),
      },
      {
        path: 'law-enforcement/inspection',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.inspection_management?.title,
        data: { breadcrumb: '', page_title: '' },
        loadChildren: () =>
          import('./modules/inspection/inspection.module').then(
            (m) => m.InspectionModule
          ),
      },
      {
        path: 'law-enforcement/grievance-management',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.grievance_management?.page_title,
        data: {
          breadcrumb: 'Grievance Management',
          page_title: 'Grievance Management',
        },
        loadChildren: () =>
          import(
            './modules/grievance-management/grievance-management.module'
          ).then((m) => m.GrievanceManagementModule),
      },
      {
        path: 'dts-operation/forecast',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.forecast_management?.title,
        data: { breadcrumb: 'Forecast', page_title: 'Forecast' },
        loadChildren: () =>
          import('./modules/forecast/forecast.module').then(
            (m) => m.ForecastModule
          ),
      },
      {
        path: 'iam',
        title:
          systemConfig?.page_title + ' | ' + staticText?.audit_log?.page_title,
        data: { breadcrumb: '', page_title: '' },
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: 'scp-mgmt/scp-registration',
        title:
          systemConfig?.page_title + ' | ' + staticText?.scp?.scp_page_title,
        data: {
          mode: scp.registration,
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'Self Registration Request',
          page_title: 'Self Registration Request',
        },
        component: ScpListComponent,
      },
      {
        path: 'scp-mgmt/scp-onboard',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.onboard_page_title,
        data: {
          mode: scp.onboard,
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'Onboard process Requests',
          page_title: 'Onboard process Requests',
        },
        component: ScpListComponent,
      },
      {
        path: 'scp-mgmt/scp-list',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.onboard_page_title,
        data: {
          mode: scp.scp_list,
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'Onboard process Requests',
          page_title: 'Onboard process Requests',
        },
        component: ScpListComponent,
      },
      {
        path: 'scp-mgmt/onboarding-preview/:id',
        title:
          staticText?.login?.login_page_description +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.PREVIEW,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.ONBOARDING,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/view-onboarding-status/:id',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.VIEW,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.ONBOARDING,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/view-status/:id',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.VIEW,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.REGISTRATION,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/view-onboarding-status/:id/:view-mode',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.VIEW,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.ONBOARDING,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/view-status/:id/:view-mode',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.VIEW,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.REGISTRATION,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/edit-registration/:id',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: {
          mode: SCREENMODE.EDIT,
          id: USERTYPE.ADMIN_ID,
          operation_type: SCREENMODE.REGISTRATION,
          breadcrumb: '',
          page_title: '',
        },
        component: ViewRegistrationComponent,
      },
      {
        path: 'scp-mgmt/application-status',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.application_status?.title,
        data: { id: USERTYPE.SCP_ID, breadcrumb: '', page_title: '' },
        component: ViewApplicationComponent,
      },
      {
        path: 'scp-mgmt/onboard-scp/:id',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.scp_onboard_page_title,
        data: {
          mode: SCREENMODE.EDIT,
          id: USERTYPE.ADMIN_ID,
          breadcrumb: '',
          page_title: '',
        },
        component: OnboardScpComponent,
      },
      {
        path: 'scp-mgmt/onboard-scp/:id/:stepper',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.scp?.scp_onboard_page_title,
        data: {
          mode: SCREENMODE.EDIT,
          id: USERTYPE.ADMIN_ID,
          breadcrumb: '',
          page_title: '',
        },
        component: OnboardScpComponent,
      },
      {
        path: 'configuration/product',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.product_management?.product_page_title,
        data: {
          id: USERTYPE.ADMIN_ID,
          breadcrumb: 'Product',
          page_title: 'Product',
        },
        loadChildren: () =>
          import('./modules/product/product.module').then(
            (m) => m.ProductModule
          ),
      },
      {
        path: 'configuration',
        title:
          systemConfig?.page_title +
          ' | ' +
          staticText?.product_management?.product_page_title,
        data: {
          id: USERTYPE.ADMIN_ID,
        },
        loadChildren: () =>
          import('./modules/master/master.module').then((m) => m.MasterModule),
      },
    ],
  },
  {
    path: 'dts',
    title:
      systemConfig?.page_title +
      ' | ' +
      staticText?.setup_configuration?.branding_setup,
    data: {
      id: USERTYPE.ADMIN_ID,
    },
    resolve: {
      resolvedData: CommonService,
    },
    loadChildren: () =>
      import('./modules/initial-setup/initial-setup.module').then(
        (m) => m.InitialSetupModule
      ),
  },
  {
    path: 'under-developement',
    title: `${systemConfig?.page_title} | Statisics`,
    data: {
      breadcrumb: 'Under Developement',
      page_title: 'Under Developement',
      requiredPermission: [PagePermssions.DAILYCASE_MENU_PERMISSION],
    },
    component: UnderDevelopmentComponent,
  },
  {
    path: 'self-register', // New route for the standalone component
    title:
      systemConfig?.page_title +
      ' | ' +
      staticText?.scp?.self_registration_form,
    component: SelfRegisterComponent,
    canActivate: [], // Optional: Protect this route with AuthGuard
    data: {
      breadcrumb: 'Self Registration Form',
      page_title: 'Self Registration Form',
      requiredPermission: [],
    },
    loadChildren: () =>
      import('./core/error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule
      ),
  },
  {
    path: '**',
    redirectTo: routePath?.VIEW_REDIRECT_404_PATH,
    pathMatch: 'full',
  },
  {
    path: `${routePath?.VIEW_PATH}`,
    data: {},
    loadChildren: () =>
      import('./core/error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
