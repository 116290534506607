

<section class="header container-fluid">
    <div class="row d-flex align-items-center py" #header>

        <div class="header_logo_flx">

            <div class="application-logo">
                <img src="assets/images/common/logo.svg" class="" alt="logo">
            </div>

            <div class="">
                <div class="mb-0  fw-300 d_flx_rwanda">
                    <span class="username">
                        <img src="assets/images/common/rwanda_logo.svg"  alt="logo" class="">
                    </span>
                    <span class="rwanda_img">{{staticText?.common?.company_secondary_name || "Rwanda Digital Tax
                        Stamp System"}}</span>
                </div>
            </div>
        </div>
    </div>
</section>