import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-initiate-onboarding',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './initiate-onboarding.component.html',
  styleUrl: './initiate-onboarding.component.scss'
})
export class InitiateOnboardingComponent {
  constructor(public _createDialogRef: MatDialogRef<InitiateOnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public majorDialogData: any

  ) { }

  close(){
    this._createDialogRef.close(false);
  }

  onboarding(){
    this._createDialogRef.close(true);
  }
}
