export const initialState: AppState = {
    region: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    mzo: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    district: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    county: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    sub_county: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    parish: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    village: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    basis: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    tenure: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    documentCategory: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    valuationType: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    valuation: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    methodology: {
        list: [],
        isInProgress: false,
        isLoaded: false
    },
    propertyType: {
        list: [],
        isInProgress: false,
        isLoaded: false 
    }
}

export interface AppState {
    region: MasterState,
    mzo: MasterState,
    district: MasterState,
    county: MasterState,
    sub_county: MasterState,
    parish: MasterState,
    village: MasterState,
    basis: MasterState,
    tenure: MasterState,
    documentCategory: MasterState,
    valuationType: MasterState,
    valuation: MasterState,
    methodology: MasterState,
    propertyType: MasterState
}

export interface MasterState {
    list: any[];
    isInProgress: boolean;
    isLoaded: boolean;
}